import React, { FC } from "react"
import { useNavigate } from "react-router-dom"

import {
  Card,
  Row,
  Typography,
  Empty,
} from "antd"

import { TStaffListItem } from 'api/hooks/useStaffService/useStaffService.types';
import { AvatarComponent } from "common/components/staff/AvatarComponent/AvatarComponent"
import styles from "common/components/staff/cardView/CardView.module.scss"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { getFormatBirthDate } from "utils/date"
import {
  getContactInfo,
  getPositionInfo,
} from "utils/user"
import { RouteNames } from "variables/routes"


interface IUserListProps {
    users: TStaffListItem[]
}

export const UserList: FC<IUserListProps> = ({ users }) => {
  const { id } = useTypedSelector(state => state.currentUser)

  const navigate = useNavigate()

  const pushUserCardPage = (userId: number): void => {
    if (`${id}` !== `${userId}`) {
      navigate(`${RouteNames.UserStatic}/${userId}`)
    } else {
      navigate(RouteNames.Profile)
    }
  }

  if (!users.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  return (
    <div>
      {
        users.map((user, index: number) => {
          return (
            <Card
              className={styles.card}
              key={index}
              size={"small"}
            >
              <Card.Meta
                avatar={<AvatarComponent personal={user.personal} />}
                title={
                  (
                    <div
                      className={styles.cardTitle}
                      onClick={() => pushUserCardPage(user.id)}
                      style={{ marginTop: 5 }}
                    >
                      {user.email}
                    </div>
                  )
                }
              />

              <br />

              <Row>
                <div className={styles.rowTitle}>ФИО:</div>

                <Typography.Paragraph>
                  {
                    user.personal
                      ? `${user.personal.second_name} ${user.personal.first_name} ${user.personal.middle_name}`
                      : "-"
                  }
                </Typography.Paragraph>
              </Row>

              <Row>
                <div className={styles.rowTitle}>Должность:</div>

                <Typography.Paragraph>
                  {getPositionInfo(user.specialization?.positions, "-")}
                </Typography.Paragraph>
              </Row>

              <Row>
                <div className={styles.rowTitle}>Дата рождения:</div>

                <Typography.Paragraph>
                  {user.personal ? getFormatBirthDate(user.personal.birth_date) : "-"}
                </Typography.Paragraph>
              </Row>

              <Row>
                <div className={styles.rowTitle}>Телефон:</div>

                <Typography.Paragraph>
                  {getContactInfo(user.contacts, 4, "-")}
                </Typography.Paragraph>
              </Row>
            </Card>
          )
        })
      }
    </div>
  )
}
