import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { resetStoreApp } from "stores/redux/slices/app"
import { resetStoreAuth } from 'stores/redux/slices/auth';
import { resetStoreClients } from "stores/redux/slices/clients"
import { resetStoreClientsEdit } from "stores/redux/slices/clients-edit"
import { resetStoreUser } from "stores/redux/slices/current-user"
import { resetStoreLeads } from "stores/redux/slices/leads"
import { resetStoreLeadsEdit } from "stores/redux/slices/leads-edit"
import { resetStoreOptionsDocuments } from "stores/redux/slices/options-documents"
import { resetStoreOptionsReferences } from "stores/redux/slices/options-references"
import { resetStoreStaff } from "stores/redux/slices/staff"
import { resetStoreStaffEdit } from "stores/redux/slices/staff-edit"
import { resetStoreVacation } from "stores/redux/slices/vacation"
import { resetStoreWidgets } from "stores/redux/slices/widgets"
import { resetStoreWiki } from "stores/redux/slices/wiki"

const resetFns: ActionCreatorWithoutPayload<string>[] = [
  resetStoreApp,
  resetStoreAuth,
  resetStoreClients,
  resetStoreClientsEdit,
  resetStoreUser,
  resetStoreLeads,
  resetStoreLeadsEdit,
  resetStoreOptionsDocuments,
  resetStoreOptionsReferences,
  resetStoreStaff,
  resetStoreStaffEdit,
  resetStoreVacation,
  resetStoreWidgets,
  resetStoreWiki,
]

export const useResetStores = () => {
  const dispatch = useDispatch();

  /**
   * Сбрасываем все сторы
   */
  const reset = useCallback(() => resetFns.forEach(fn => dispatch(fn())), [ dispatch ]);

  return { reset };
};
