import { TErrorLog } from 'common/types/types';
import { consoleErrorGrouped } from 'utils/console';
import { LOCAL_STORAGE_ERRORS_LOGGED } from 'variables/local-storage-keys';

/**
 * Process app errors
 * @function {(error: Error, info: {componentStack: string}): void} errorHandler
 *
 * !!! Be careful. Don't use redux, graphql (etc) here.
 * Current handler might be used inside "critical.tsx".
 * Read more in "critical.tsx"
 *
 * @param {Error} error - err text
 * @param {{ componentStack: string }} info - info about err stack
 */
export const errorBoundaryHandler = (error: Error, info: { componentStack: string }) => {
  let newError: TErrorLog | undefined;

  if (error?.name) {
    newError = {
      ...error,
      description: info.componentStack,
    };
  } else if (error && typeof error === 'string') {
    newError = {
      description: info.componentStack,
      name: error,
    };
  }

  if (newError) {
    let isAlreadyLogged = false;
    let errors = [] as TErrorLog[];
    const loggedErrors = localStorage.getItem(LOCAL_STORAGE_ERRORS_LOGGED);

    if (loggedErrors) {
      const loggedErrorsParsed: TErrorLog[] = JSON.parse(loggedErrors);

      if (loggedErrorsParsed && Array.isArray(loggedErrorsParsed) && loggedErrorsParsed.length) {
        errors = loggedErrorsParsed;
      }
    }

    if (!errors.length) {
      localStorage.setItem(LOCAL_STORAGE_ERRORS_LOGGED, JSON.stringify([ newError ]));
    } else {
      const isLogged = newError?.name && errors.find(err => err.name === newError?.name);

      if (isLogged) {
        isAlreadyLogged = true;
      } else {
        localStorage.setItem(LOCAL_STORAGE_ERRORS_LOGGED, JSON.stringify([ ...errors, newError ]));
      }
    }

    if (!isAlreadyLogged) {
      /* TODO: add errors logging to back-end */
      // Do something with the newError
    }

    consoleErrorGrouped({
      groupName: 'Error boundary:',
      messages: [ { message: `Error: ${error}` }, { message: `Error componentStack: ${info.componentStack}` } ],
    });
  }
};
