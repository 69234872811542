import Cookies from "js-cookie"

import { COOKIE_TOKEN } from "utils/cookies"

export const isLoggedIn = (): boolean => {
  const access = Cookies.get(COOKIE_TOKEN)
  if (access) {
    return access?.length > 0
  } else {
    return false
  }
}
