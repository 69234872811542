import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { IAuthState } from "stores/redux/slices/auth/types"
import { IStaffRole } from 'stores/redux/slices/staff/types';

const initialState: IAuthState = {
  error: "",
  isAuth: false,
  isLoading: true,
  roles: [],
}

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    resetStoreAuth(state: IAuthState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IAuthState]);
    },
    setAuthError(state: IAuthState, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setAuthLoading(state: IAuthState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setIsAuth(state: IAuthState, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setRole(state: IAuthState, action: PayloadAction<Array<IStaffRole>>) {
      state.roles = action.payload
    },
  },
});

export const {
  resetStoreAuth,
  setAuthError,
  setAuthLoading,
  setIsAuth,
  setRole,
} = authSlice.actions;

export default authSlice.actions;
