import React from 'react'
import {
  useState,
  FC,
} from "react"

import {
  Avatar,
  Upload,
} from "antd"

import Icon, {
  UserOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { AvatarSize } from "antd/es/avatar/SizeContext"
import { FilesService } from "api/FilesService"
import { useStaffEditService } from "api/hooks/useStaffService/useStaffEditService"
import { URL_USER_AVATAR } from "api/urlList"
import styles from "common/components/user/avatar/UserAvatar.module.scss"
import { useActions } from "common/hooks/useActions"
import { TFile } from "models/IDocumentsData"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"


interface IAvatarProps {
    editable?: boolean;
    size: AvatarSize;
    src: string | undefined;
    style?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
    userId: number;
}

const uploadImageSvg = () => (
  <svg
    fill="currentColor"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z" />
  </svg>
)

const UploadImageIcon = (props: any) => (/* eslint-disable-line @typescript-eslint/no-explicit-any */
  <Icon
    component={uploadImageSvg}
    {...props}
  />
)

export const UserAvatar: FC<IAvatarProps> = props => {
  const {
    editable,
    size,
    src,
    style,
    userId,
  } = props

  const {
    setAppMessage,
    setUserAvatar,
  } = useActions()

  const { updateUserAvatar } = useStaffEditService()

  const { id } = useTypedSelector(state => state.currentUser)

  const [ image, setImage ] = useState(src)
  const [ isImageLoading, setImageLoading ] = useState(false)

  const beforeUpload = (file: TFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"

    if (!isJpgOrPng) {
      setAppMessage("Вы можете загружать только JPG/PNG файлы!")
    }

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      setAppMessage("Изображение должно быть меньше 2MB!")
    }

    return isJpgOrPng && isLt2M
  }

  const uploadHandleChange = (info: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (info.file.status === "uploading") {
      setImageLoading(true)
    }
  }

  const uploadRequest = async (e: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    try {
      setImageLoading(true)
      const response = await FilesService.uploadAvatar(e.file)

      if (response.status === 201) {
        const imageResponse = await FilesService.getFile(response.data.id, "blob")
        const imageBase64 = URL.createObjectURL(imageResponse.data)
        if (userId === id) {
          setUserAvatar({
            id: response.data.id,
            url: `${imageBase64}`,
          })
        }
        setImage(`${imageBase64}`)
        updateUserAvatar(userId, response.data.id)
        e.onSuccess()
        setImageLoading(false)
      } else {
        e.onError()
        setImageLoading(false)
      }
    } catch (error) {
      e.onError()
      setImageLoading(false)
    }
  }

  if (editable){
    return (
      <Upload
        action={URL_USER_AVATAR}
        beforeUpload={beforeUpload}
        className={styles.avatarUploader}
        customRequest={uploadRequest}
        onChange={uploadHandleChange}
        showUploadList={false}
      >
        <div
          className={
            isImageLoading
              ? `${styles.avatarWrapper} ${styles.avatarWrapperActive}`
              : styles.avatarWrapper
          }
        >
          {
            isImageLoading ? (
              <LoadingOutlined style={
                {
                  color: "#ffffff",
                  fontSize: "35px",
                }
              }
              />
            ) : (
              <UploadImageIcon style={
                {
                  color: "#ffffff",
                  fontSize: "35px",
                }
              }
              />
            )
          }
        </div>

        <Avatar
          icon={<UserOutlined />}
          size={140}
          src={image}
        />
      </Upload>
    )
  }

  if (src){
    return (
      <Avatar
        size={size}
        src={src}
        style={style}
      />
    );
  }

  return (
    <Avatar
      icon={<UserOutlined />}
      size={size}
      style={style}
    />
  );
}
