import React, { useMemo } from "react"
import { FC } from "react"
import { Link } from "react-router-dom"

import { Dropdown } from "antd"

import { ExportOutlined } from "@ant-design/icons"
import { MenuProps } from "antd/es"
import { useAuthService } from "api/hooks/useAuthService"
import { UserAvatar } from "common/components/user/avatar/UserAvatar"
import styles from "common/components/user/header-user-info/HeaderUserInfo.module.scss"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { RouteNames } from "variables/routes"


export const HeaderUserInfo: FC = () => {
  const { logout } = useAuthService()

  const {
    avatar,
    email,
    first_name: firstName,
    id,
  } = useTypedSelector(state => state.currentUser)

  const getSlicedEmail = (emailStr: string): string => {
    return emailStr.substring(0, emailStr.lastIndexOf(""))
  }

  const menuItems: MenuProps['items'] = useMemo(() => ([ {
    key: '0',
    label: <Link to={RouteNames.Profile}>Профиль</Link>,
  }, {
    key: '1',
    label: <>Выйти <ExportOutlined /></>,
    onClick: logout,
  } ]), [ logout ]);

  return (
    <Dropdown
      arrow
      menu={{ items: menuItems }}
      placement="bottomRight"
      trigger={[ "click", "hover" ]}
    >
      <div className={styles.header}>
        <p className={styles.username}>
          {firstName ? firstName : getSlicedEmail(email)}
        </p>

        <UserAvatar
          size="default"
          src={avatar.url}
          userId={id}
        />
      </div>
    </Dropdown>
  )
}
