import dayjs, { Dayjs } from "dayjs"

import utc from 'dayjs/plugin/utc'
import { consoleError } from 'utils/console';


dayjs.extend(utc)

const format = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`
}

export const getFormatBirthDate = (date: string | null): string => {
  const reg = /(\w+)-(\w+)-(\w+)/
  const matching = (...match: string[]) => `${match[3]}.${match[2]}.${match[1]}`
  return date ? date.slice(0, 10).replace(reg, matching) : "-"
}

export const getFormatDate = (timestamp: number): string => {
  const d = new Date(timestamp * 1000)

  return `${format(d.getDate())}.${format(d.getMonth() + 1)}.${d.getFullYear()}`
}

export const getFormatTimestamp = (timestamp: number, inSeconds = true): number => {
  return inSeconds ? timestamp / 1000 : timestamp
}

export const getTimestamp = (date: Date): number => {
  const utcTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return Math.round(utcTime.getTime() / 1000)
}

export const validateBirthDate = (date: Dayjs) => {
  const birthDate = date.toDate()
  const currentDate = new Date()

  if (currentDate.getFullYear() - birthDate.getFullYear() < 18) {
    return true
  }

  if (currentDate.getFullYear() - birthDate.getFullYear() === 18) {
    if (currentDate.getMonth() < birthDate.getMonth()) {
      return true
    }

    if (currentDate.getMonth() === birthDate.getMonth()) {
      if (currentDate.getDate() < birthDate.getDate()) {
        return true
      }
    }
  }

  return false
}

export const validateEmploymentDate = (date: Dayjs) => {
  const dateYear = date.get('year')
  const dateCurrentYear = dayjs().get('year')

  const isTooLong = dateYear - dateCurrentYear > 50
  const isTooSoon = dateCurrentYear - dateYear > 50

  return isTooLong || isTooSoon;
}

export const validateWorkStartDate = (date: Dayjs) => {
  const startDate = date.toDate()
  const currentDate = new Date()

  if (currentDate.getFullYear() - startDate.getFullYear() < 0) {
    return true
  }

  if (currentDate.getFullYear() - startDate.getFullYear() === 0) {
    if (currentDate.getMonth() < startDate.getMonth()) {
      return true
    }

    if (currentDate.getMonth() === startDate.getMonth()) {
      if (currentDate.getDate() < startDate.getDate()) {
        return true
      }
    }
  }

  return false
}

export const checkDateDayjs = (date: string | Dayjs): Dayjs | null => {
  if (date){
    if (typeof date === 'string' && dayjs(date).isValid()){
      return dayjs(date);
    }

    if (typeof date === 'object' && dayjs.isDayjs(date)){
      return date;
    }
  }

  return null;
}

export const getFormattedDateDayjs = (date: Dayjs, dateFormat?: 'DD.MM.YYYY'): string => {
  if (!date || !dayjs.isDayjs(date)){
    consoleError({ message: `
    Error in: "getFormattedDateDayjs" function
    Expected: (date: Dayjs)
    Received: ${date}
    ` });

    return '';
  }

  return date.format(dateFormat);
}

export const getUtcDayStartFromDayjs = (date: Dayjs): string => {
  if (!date || !dayjs.isDayjs(date)){
    consoleError({ message: `
    Error in: "getUtcFromDayjsDayStart" function
    Expected: (date: Dayjs)
    Received: ${date}
    ` });

    return '';
  }

  return dayjs
    .utc(date.format('YYYY-MM-DD'))
    .utcOffset(0, false)
    .format();
}

export const getFormattedDate = (date: Date): string => {
  return `${format(date.getDate())}.${format(date.getMonth() + 1)}.${date.getFullYear()}`
}

export const calcDaysCountBetweenDates = (startDateStr: Date | string,
  endDateStr: Date | string): string => {
  if (startDateStr && endDateStr) {
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)

    const difference = endDate.getTime() - startDate.getTime()

    return `${difference / (1000 * 3600 * 24)}`
  } else {
    return "0"
  }
}

export const renderVacationDates = (
  startDate: Date | null,
  endDate: Date | null,
  isStartDate?: boolean,
  isEndDate?: boolean,
  inline = false,
  // eslint-disable-next-line max-params
) => {
  if (startDate && endDate) {
    if (inline) {
      return `${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`
    } else if (isStartDate) {
      return `${getFormattedDate(startDate)}`
    } else if (isEndDate) {
      return `${getFormattedDate(endDate)}`
    } else {
      return `${getFormattedDate(startDate)} \n ${getFormattedDate(endDate)}`
    }
  } else {
    return undefined
  }
}
