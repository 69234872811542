import axios, { AxiosResponse } from "axios"

import { getToken } from "utils/cookies"

import {
  axiosConfigPrivate,
  axiosConfigFile,
} from "./config"
import {
  URL_FILE,
  URL_USER_AVATAR,
} from "./urlList"

export const FilesService = {
  deleteFile: async (id: number): Promise<AxiosResponse> => {
    return axios.delete(`${URL_FILE}/${id}`, axiosConfigPrivate())
  },
  
  getFile: async (id: string | number, responseType: any): Promise<AxiosResponse> => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    return axios({
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
      },
      method: "get",
      responseType,
      url: `${URL_FILE}?id=${id}`,
    })
  },

  uploadAvatar: async (file: File): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append("file", file)

    return axios.post(URL_USER_AVATAR, formData, axiosConfigFile())
  },

  uploadDocTypeFile: async (file: File): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append("file", file)
    return axios.post(`${URL_FILE}/document_types`, formData, axiosConfigFile())
  },
  
  uploadDocumentFile: async (file: any): Promise<AxiosResponse> => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    const formData = new FormData()
    formData.append("file", file)
    return axios.post(`${URL_FILE}/documents`, formData, axiosConfigFile())
  },

  uploadFile: async (file: File, folder?: string): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append("file", file)
    if (folder) {
      formData.append("folder", folder)
    }

    return axios.post(URL_FILE, formData, axiosConfigFile())
  },

  uploadLeadFiles: async (file: File): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append("file", file)

    return axios.post(`${URL_FILE}/lead_files`, formData, axiosConfigFile())
  },
  
  uploadWikiPrevImage: async (file: any): Promise<AxiosResponse> => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    const formData = new FormData()
    formData.append("file", file)
    return axios.post(`${URL_FILE}/article_preview`, formData, axiosConfigFile())
  },
}

