export enum RouteNames {
    Article = "/wiki/article/:id",
    ArticleStatic = "/wiki/article",
    ClientCard = "/clients/client/:id",
    ClientCardStatic = "/clients/client",
    Clients = "/clients",
    CreateArticle = "/wiki/create",
    CreateClient = "/clients/create",
    CreateLead = "/leads/create",
    CreateUser = "/users/create",
    Documents = "/documents",
    DocumentsCheck = "/check-documents",
    DocumentsRequired = "/upload-documents",
    EditArticle = "/wiki/edit/:id",
    EditArticleStatic = "/wiki/edit",
    EditClient = "/clients/edit/:id",
    EditClientStatic = "/clients/edit",
    EditLead = "/leads/edit/:id",
    EditLeadStatic = "/leads/edit",
    EditProfile = "/profile/edit",
    EditUser = "/users/edit/:id",
    EditUserStatic = "/users/edit",
    Error = "/error",
    Home = "/",
    Lead = "/leads/lead/:id",
    LeadStatic = "/leads/lead",
    Leads = "/leads",
    PasswordReset = "/reset",
    PasswordRestore = "/restore",
    Profile = "/profile",
    References = "/references",
    SignIn = "/sign-in",
    User = "/users/user/:id",
    UserList = "/users",
    UserStatic = "/users/user",
    Vacation = "/vacation",
    Wiki = "/wiki",
}

export const breadcrumbsNames: { [key: string]: string } = {
  "/": "Главная",
  "/clients": "Клиенты",
  "/clients/client": "Карточка клиента",
  "/clients/create": "Добавить клиента",
  "/clients/edit": "Изменить клиента",
  "/documents": "Документы",
  "/leads": "Лиды",
  "/leads/create": "Добавить лид",
  "/leads/edit": "Изменить лид",
  "/leads/lead": "Карточка лида",
  "/profile": "Профиль",
  "/profile/edit": "Редактирование",
  "/references": "Справочники",
  "/users": "Сотрудники",
  "/users/create": "Добавить сотрудника",
  "/users/edit": "Изменить сотрудника",
  "/users/user": "Карточка сотрудника",
  "/vacation": "График отпусков",
  "/wiki": "Wiki",
  "/wiki/article": "Статья",
  "/wiki/create": "Создание статьи",
  "/wiki/edit": "Редактирование статьи",
}

/*

TODO:

Доступно менеджеру

- список клиентов
- карточка клиента
- создание клиента
- редактирование карточки клиента

- список лидов
- карточка лида
- создание лида
- редактирование карточки лида

- список сотрудников
- карточка сотрудника
С ограничениями как и для обычных сотрудников

- список отпусков
видеть можно всех, редактировать и создавать только свои данные

- страница wiki
также по ролям

 */

export const routesForEveryone = [
  /* wiki */
  RouteNames.Article,
  RouteNames.ArticleStatic,
  RouteNames.Wiki,

  /* проверка документов */
  RouteNames.DocumentsCheck,
  RouteNames.DocumentsRequired,

  /* профиль */
  RouteNames.EditProfile,
  RouteNames.Profile,

  /* ошибка */
  RouteNames.Error,

  /* домашняя */
  RouteNames.Home,

  /* сброс пароля */
  RouteNames.PasswordReset,
  RouteNames.PasswordRestore,

  /* вход */
  RouteNames.SignIn,

  /* отпуска */
  RouteNames.Vacation,
]

export const routesForManager = [
  ...routesForEveryone,

  /* пользователь */
  RouteNames.User,
  RouteNames.UserList,
  RouteNames.UserStatic,

  /* лиды */
  RouteNames.CreateLead,
  RouteNames.EditLead,
  RouteNames.EditLeadStatic,
  RouteNames.Lead,
  RouteNames.LeadStatic,
  RouteNames.Leads,

  /* клиенты */
  RouteNames.ClientCard,
  RouteNames.ClientCardStatic,
  RouteNames.Clients,
  RouteNames.CreateClient,
  RouteNames.EditClient,
  RouteNames.EditClientStatic,
]

export const routesOnlyForAdmin = [
  /* создание / редактирование пользователя */
  RouteNames.CreateUser,
  RouteNames.EditUser,
  RouteNames.EditUserStatic,

  /* документы */
  RouteNames.Documents,

  /* справочники */
  RouteNames.References,

  /* создание / редактирование статей */
  RouteNames.CreateArticle,
  RouteNames.EditArticle,
  RouteNames.EditArticleStatic,
]

export const routesForAdmin = [ ...routesForManager, ...routesOnlyForAdmin ]
