import { useMemo } from "react"

import axios, { AxiosResponse } from "axios"

import { axiosConfigPrivate } from "api/config"
import {
  TDocumentFetched,
  TDocumentUploaded,
} from "api/types/documents"
import {
  URL_BASE_DOCUMENTS,
  URL_DOCUMENTS,
  URL_DOCUMENTS_SIGNIN,
} from "api/urlList"
import { useProcessResponseError } from 'common/hooks/useProcessResponseError';
import {
  IDocumentsAddFileData,
  IDocumentsForReuploadData,
  IDocumentsForSignInData,
  IDocumentsForSignInList,
} from "models/IDocumentsData"
import { useTypedDispatch } from "stores/redux/hooks/useTypedDispatch"
import {
  setAppGlobalError,
  setAppSuccess,
} from "stores/redux/slices/app"
import {
  addDocument,
  deleteDocument,
  resetStoreOptionsDocuments,
  setDocumentsList,
  setDocumentsLoading,
  setDocumentsUploadResult,
  setUpdatedDocument,
} from "stores/redux/slices/options-documents"
import { IDocumentEditData } from "stores/redux/slices/options-documents/types"


export const useDocumentsService = () => {

  const dispatch = useTypedDispatch()
  const { processResponseError } = useProcessResponseError()

  const actions = useMemo(() => ({
    addFilesForDocument: async (data: IDocumentsAddFileData): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)

      return axios.put(URL_BASE_DOCUMENTS, body, axiosConfigPrivate())
    },

    createDocument: async (data: IDocumentEditData): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        active: data.active,
        file: { id: data.file_id },
        name: data.name,
      })

      return axios.put(`${URL_DOCUMENTS}`, body, axiosConfigPrivate())
    },

    deleteDocument: async (id: number): Promise<AxiosResponse> => {
      return axios.delete(`${URL_DOCUMENTS}`, {
        ...axiosConfigPrivate(),
        data: { id },
      })
    },

    fetchDocuments: async (): Promise<AxiosResponse> => {
      return axios.get(URL_DOCUMENTS, axiosConfigPrivate())
    },

    fetchDocumentsByStatus: async (status: string): Promise<AxiosResponse> => {
      return axios.get(`${URL_BASE_DOCUMENTS}?status=${status}`,
        axiosConfigPrivate())
    },

    fetchDocumentsByUserId: async (id: number,
      status: string): Promise<AxiosResponse> => {
      return axios.get(`${URL_BASE_DOCUMENTS}/${id}?status=${status}`,
        axiosConfigPrivate())
    },

    fetchDocumentsForSignIn: async (): Promise<AxiosResponse> => {
      return axios.get(URL_DOCUMENTS_SIGNIN, axiosConfigPrivate())
    },

    reuploadFileForDocument: async (data: any): Promise<AxiosResponse<TDocumentUploaded>> => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
      const body = JSON.stringify(data)

      return axios.patch(URL_BASE_DOCUMENTS, body, axiosConfigPrivate())
    },

    setVerification: async (id: number,
      verified: boolean,
      // eslint-disable-next-line max-params
      comment: string): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        comment,
        verified,
      })
      return axios.patch(`${URL_BASE_DOCUMENTS}/${id}/verification`,
        body,
        axiosConfigPrivate())
    },

    updateDocument: async (data: IDocumentEditData): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        active: data.active,
        file: { id: data.file_id },
        id: data.id,
        name: data.name,
      })

      return axios.patch(`${URL_DOCUMENTS}`, body, axiosConfigPrivate())
    },
  }), [])

  return {
    actions,
    createDocument:
        async (data: IDocumentEditData, callback: () => void) => {
          try {
            const response = await actions.createDocument(data)

            if (response.status === 201 || response.status === 200) {
              callback()
              dispatch(addDocument(response.data))
              dispatch(setAppSuccess("Документ успешно создан."))
            } else {
              processResponseError(response)
            }
          } catch (err) {
            dispatch(setAppGlobalError({
              message: [ "" ],
              status: 500,
            }))
          }
        },
    fetchDocumentsForSignIn: async ()  => {
      try {
        dispatch(resetStoreOptionsDocuments())
        dispatch(setDocumentsLoading(true))

        const response = await actions.fetchDocumentsForSignIn()

        if (response.status === 200 || response.status === 201) {
          dispatch(setDocumentsList(response.data))
        } else {
          processResponseError(response)
        }

        return response;
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      } finally {
        dispatch(setDocumentsLoading(false))
      }
    },
    fetchDocumentsList: async () => {
      try {
        dispatch(resetStoreOptionsDocuments())
        dispatch(setDocumentsLoading(true))

        const response = await actions.fetchDocuments()
        const data: TDocumentFetched[] = response.data || []

        if (response.status === 200 || response.status === 201) {
          dispatch(setDocumentsList(data))
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      } finally {
        dispatch(setDocumentsLoading(false))
      }
    },
    removeDocument: async (id: number) => {
      try {
        const response = await actions.deleteDocument(id)

        if (response.status === 201 || response.status === 200) {
          dispatch(deleteDocument(id))
          dispatch(setAppSuccess("Документ успешно удалён."))
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },
    reuploadRefusedDocuments:
        async (files: { [key: string]: Array<IDocumentsForReuploadData> }) => {
          dispatch(setDocumentsLoading(true))

          let result = true
          let responses: TDocumentUploaded[] = []

          const promises = Object.entries(files).map(async (item: [string, Array<IDocumentsForReuploadData>]) => {
            const docId = item[0]
            let docFiles: Array<IDocumentsForReuploadData["sendFile"]> = []

            item[1].forEach((doc: IDocumentsForReuploadData) => {
              docFiles.push(doc.sendFile)
            })

            const data = {
              comment: "1",
              document_files: docFiles,
              document_id: parseInt(docId),
            }

            return actions.reuploadFileForDocument(data)
          })

          await Promise.all(promises)
            .then(r => {
              r.forEach(response => {
                if (response.status !== 200 && response.status !== 201) {
                  result = false
                  processResponseError(response)
                } else {
                  responses.push(response.data)
                }
              })

              dispatch(setDocumentsUploadResult(result))
            })
            .catch(() => {
              dispatch(setAppGlobalError({
                message: [ "" ],
                status: 500,
              }))
            })
            .finally(() => {
              dispatch(setDocumentsLoading(false))
            })

          return responses;
        },

    updateDocument:
        async (data: IDocumentEditData, callback?: () => void) => {
          try {
            const response = await actions.updateDocument(data)

            if (response.status === 201 || response.status === 200) {
              callback?.()
              dispatch(setUpdatedDocument(response.data))
              dispatch(setAppSuccess("Документ успешно изменён."))
            } else {
              processResponseError(response)
            }
          } catch (err) {
            dispatch(setAppGlobalError({
              message: [ "" ],
              status: 500,
            }))
          }
        },

    uploadFilesForDocument:
        async (files: IDocumentsForSignInList) => {

          let result = true

          const promises = Object.entries(files).map(async (item: [string, Array<IDocumentsForSignInData>]) => {
            const docId = item[0]
            let docFiles: Array<{ id: string | number }> = []

            item[1].forEach((doc: { id: string | number }) => {
              docFiles.push({ id: doc.id })
            })

            const data = {
              comment: "1",
              document_files: docFiles,
              document_type_id: parseInt(docId),
            }

            return actions.addFilesForDocument(data)
          })

          await Promise.all(promises)
            .then(responses => {
              responses.forEach(response => {
                if (response.status !== 200 && response.status !== 201) {
                  result = false
                  processResponseError(response)
                }
              })

              dispatch(setDocumentsUploadResult(result))
            })
            .catch(() => {
              dispatch(setAppGlobalError({
                message: [ "" ],
                status: 500,
              }))
            })
            .finally(() => {
              dispatch(setDocumentsLoading(false))
            })
        },
  };
}
