import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  TWikiData,
  TWikiShortSection,
  TWikiSingleArticle,
  TWikiState, 
} from "stores/redux/slices/wiki/types"


const initialState: TWikiState = {
  currentArticleData: null,
  isAddArticleResult: false,
  isAddSectionResult: false,
  isDeleteArticleResult: false,
  isDeleteSectionResult: false,
  isEditArticleResult: false,
  isEditSectionResult: false,
  isLoading: false,
  sectionsData: [],
  wikiData: [],
}

export const wikiSlice = createSlice({
  initialState,
  name: 'wiki',
  reducers: {
    resetStoreWiki(state: TWikiState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof TWikiState]);
    },
    setWikiArticleAdd(state: TWikiState, action: PayloadAction<boolean>) {
      state.isAddArticleResult = action.payload
    },
    setWikiArticleDelete(state: TWikiState, action: PayloadAction<boolean>) {
      state.isDeleteArticleResult = action.payload
    },
    setWikiArticleEdit(state: TWikiState, action: PayloadAction<boolean>) {
      state.isEditArticleResult = action.payload
    },
    setWikiCleanSectionsData(state: TWikiState) {
      state.sectionsData = initialState.sectionsData
    },
    setWikiCurrentArticleData(state: TWikiState, action: PayloadAction<TWikiSingleArticle>) {
      state.currentArticleData = action.payload
    },
    setWikiData(state: TWikiState, action: PayloadAction<TWikiData[]>) {
      state.wikiData = action.payload
    },
    setWikiLoading(state: TWikiState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setWikiSectionAdd(state: TWikiState, action: PayloadAction<boolean>) {
      state.isAddSectionResult = action.payload
    },
    setWikiSectionDelete(state: TWikiState, action: PayloadAction<boolean>) {
      state.isDeleteSectionResult = action.payload
    },
    setWikiSectionEdit(state: TWikiState, action: PayloadAction<boolean>) {
      state.isEditSectionResult = action.payload
    },
    setWikiSectionsData(state: TWikiState, action: PayloadAction<TWikiShortSection[]>) {
      state.sectionsData = action.payload
    },
  },
});

export const {
  resetStoreWiki,
  setWikiArticleAdd,
  setWikiArticleDelete,
  setWikiArticleEdit,
  setWikiCleanSectionsData,
  setWikiCurrentArticleData,
  setWikiData,
  setWikiLoading,
  setWikiSectionAdd,
  setWikiSectionDelete,
  setWikiSectionEdit,
  setWikiSectionsData,
} = wikiSlice.actions;

export default wikiSlice.actions;
