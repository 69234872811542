import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { IClientsData } from "models/IClientsData"
import { IEditClientState } from "stores/redux/slices/clients-edit/types"

const initialState: IEditClientState = {
  currentClient: {
    active: false,
    client_type: {
      active: false,
      id: 0,
      type: "",
    },
    comment: "",
    contact_person: "",
    contacts: "",
    id: "",
    name: "",
    site: "",
  },
  isEditSuccess: false,
  isLoading: false,
}

export const clientsEditSlice = createSlice({
  initialState,
  name: 'clients-edit',
  reducers: {
    resetStoreClientsEdit(state: IEditClientState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IEditClientState]);
    },
    setEditClientData(state: IEditClientState, action: PayloadAction<IClientsData>) {
      state.currentClient = action.payload
    },
    setEditClientLoading(state: IEditClientState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setEditClientSuccess(state: IEditClientState, action: PayloadAction<boolean>) {
      state.isEditSuccess = action.payload
    },
  },
});

export const {
  resetStoreClientsEdit,
  setEditClientData,
  setEditClientLoading,
  setEditClientSuccess,
} = clientsEditSlice.actions;

export default clientsEditSlice.actions;
