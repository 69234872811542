import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IAppState } from './types';

const initialState: IAppState = {
  error: "",
  errorMessage: "",
  globalError: {
    errorMessage: [],
    status: 0,
  },
  success: "",
}

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    resetStoreApp(state: IAppState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IAppState]);
    },
    setAppError(state: IAppState, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setAppGlobalError(state: IAppState, action: PayloadAction<{
          message: Array<string>,
          status: number
      }>) {
      state.globalError = {
        ...state.globalError,
        errorMessage: action.payload.message,
        status: action.payload.status,
      }
    },
    setAppMessage(state: IAppState, action: PayloadAction<string>) {
      state.errorMessage = action.payload
    },
    setAppSuccess(state: IAppState, action: PayloadAction<string>) {
      state.success = action.payload
    },
  },
});

export const {
  resetStoreApp,
  setAppError,
  setAppGlobalError,
  setAppMessage,
  setAppSuccess,
} = appSlice.actions;

export default appSlice.actions;
