import React from 'react'
import { FC } from "react"
import { Link } from "react-router-dom"

import { Layout } from "antd"

import styles from "common/components/header/Header.module.scss"
import { HeaderUserInfo } from "common/components/user/header-user-info/HeaderUserInfo"


export const Header: FC = () => {
  return (
    <Layout.Header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/">
          <img
            alt="CRM logo"
            className={styles.headerLogo}
          />
        </Link>

        <HeaderUserInfo />
      </div>
    </Layout.Header>
  )
}
