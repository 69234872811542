import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { TDocumentFetched } from "api/types/documents"
import { IDocumentsState } from "stores/redux/slices/options-documents/types"

const initialState: IDocumentsState = {
  docs: [],
  error: "",
  isLoading: false,
  isUploaded: false,
}

export const optionsDocumentsSlice = createSlice({
  initialState,
  name: 'options-documents',
  reducers: {
    addDocument(state: IDocumentsState, action: PayloadAction<TDocumentFetched>) {
      state.docs = [ ...state.docs, action.payload ]
    },
    deleteDocument(state: IDocumentsState, action: PayloadAction<number>) {
      state.docs = state.docs.filter(el => el.id !== action.payload)
    },
    resetStoreOptionsDocuments(state: IDocumentsState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IDocumentsState]);
    },
    setDocumentsError(state: IDocumentsState, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setDocumentsList(state: IDocumentsState, action: PayloadAction<Array<TDocumentFetched>>) {
      state.docs = action.payload
    },
    setDocumentsLoading(state: IDocumentsState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setDocumentsUploadResult(state: IDocumentsState, action: PayloadAction<boolean>) {
      state.isUploaded = action.payload
    },
    setUpdatedDocument(state: IDocumentsState, action: PayloadAction<TDocumentFetched>) {
      state.docs = state.docs.map(el => (el.id === action.payload.id ? action.payload : el))
    },
  },
});

export const {
  addDocument,
  deleteDocument,
  resetStoreOptionsDocuments,
  setDocumentsError,
  setDocumentsList,
  setDocumentsLoading,
  setDocumentsUploadResult,
  setUpdatedDocument,
} = optionsDocumentsSlice.actions;

export default optionsDocumentsSlice.actions;
