import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import {
  roleAdmin,
  roleManager,
  roleEmployee,
} from 'variables/roles';


export const usePermissions = () => {
  const { roles } = useTypedSelector(state => state.auth)

  const isEmployee = !!roles.find(role => role.name === roleEmployee.name)
  const isManager = !!roles.find(role => role.name === roleManager.name)
  const isAdmin = !!roles.find(role => role.name === roleAdmin.name)
  const isManagerOnly = isManager && !isAdmin

  return {
    isAdmin,
    isEmployee,
    isManager,
    isManagerOnly,
  }
}
