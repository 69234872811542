import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import clsx from 'clsx';

import { openPathInSameTab } from 'utils/url';
import { RouteNames } from "variables/routes"

import s from './ErrorFallbackCritical.module.scss';

/* TODO: update design */

/**
 * Fallback component for the "ErrorBoundaryCritical.tsx" wrapper
 *
 * !!! Be careful:
 * - Various hooks will not work here (useDispatch, etc...).
 * - <FormattedMessage> will not work here
 * Read more in "ErrorBoundaryCritical.tsx" description.
 *
 * @param {{resetErrorBoundary: (...args: unknown[]) => void}} props
 * @component {React.ComponentType<FallbackProps>} ErrorFallbackCritical
 * @return {JSX.Element} fallback page
 */
export const ErrorFallbackCritical: React.ComponentType<FallbackProps> = props => {

  const { resetErrorBoundary } = props;

  const handleClickPrimary = () => {
    resetErrorBoundary();
    openPathInSameTab({ path: RouteNames.SignIn });
  };

  return (
    <div className={s.ErrorFallbackCritical__container}>
      <div className={s.ErrorFallbackCritical__content}>
        <div className={s.ErrorFallbackCritical__title}>
          <p>Oops...</p>

          <p>Something critical went wrong...</p>
        </div>

        <div className={s.ErrorFallbackCritical__description}>
          <p>Please try to return to the main page</p>

          <p>or contact our support team using the button below</p>
        </div>

        <button
          className={clsx(s.ErrorFallbackCritical__button, s.ErrorFallbackCritical__button_primary)}
          onClick={handleClickPrimary}
        >
          {'To "Main" page'}
        </button>
      </div>
    </div>
  );
};
