import { IStaffRole } from 'stores/redux/slices/staff/types';

export const roleAdmin = {
  id: 3,
  name: "administrator",
}

export const roleManager = {
  id: 2,
  name: "manager",
}

export const roleEmployee = {
  id: 1,
  name: "employee",
}

export const allowedForEveryone: IStaffRole[] = [
  roleAdmin,
  roleManager,
  roleEmployee,
]

export const allowedForManager: IStaffRole[] = [ roleManager ]
export const allowedForAdmin: IStaffRole[] = [ roleAdmin ]
export const allowedForUser: IStaffRole[] = [ roleEmployee ]
