const requireEnv = (variable: string | undefined, name: string) => {
  if (typeof variable === 'undefined') {
    throw new Error(`Env variable "${name}" is required`);
  }

  return variable;
};

export const isClient = () => typeof window === 'object';
export const isProduction = () => process?.env.NODE_ENV === 'production';
export const isNoAuth = () => !isProduction() && process?.env.REACT_APP_NO_AUTH === 'true';

export const ENV_URL_API = requireEnv(process.env.REACT_APP_URL_API, 'URL_API')
