import {
  createSlice,
  PayloadAction, 
} from "@reduxjs/toolkit"
import {
  IVacationHandledData,
  IVacationState,
} from "stores/redux/slices/vacation/types"


const initialState: IVacationState = {
  addResult: false,
  data: [],
  deleteResult: false,
  editResult: false,
  isLoading: false,
}

export const vacationSlice = createSlice({
  initialState,
  name: 'vacation',
  reducers: {
    resetStoreVacation(state: IVacationState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IVacationState]);
    },
    setVacationCreateResult(state: IVacationState, action: PayloadAction<boolean>) {
      state.addResult = action.payload
    },
    setVacationData(state: IVacationState, action: PayloadAction<IVacationHandledData[]>) {
      state.data = action.payload
    },
    setVacationDeleteResult(state: IVacationState, action: PayloadAction<boolean>) {
      state.deleteResult = action.payload
    },
    setVacationEditResult(state: IVacationState, action: PayloadAction<boolean>) {
      state.editResult = action.payload
    },
    setVacationLoading(state: IVacationState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
});

export const {
  resetStoreVacation,
  setVacationCreateResult,
  setVacationData,
  setVacationDeleteResult,
  setVacationEditResult,
  setVacationLoading,
} = vacationSlice.actions;

export default vacationSlice.actions;
