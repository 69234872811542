import Cookies from "js-cookie"

export const COOKIE_TOKEN = "BEARER"
export const COOKIE_UID = "UID"

export const getToken = () => `Bearer ${Cookies.get(COOKIE_TOKEN)}`

export const setTokens = (userId: string,
  expiresIn: string,
  // eslint-disable-next-line max-params
  accessToken: string) => {
  userId &&
        Cookies.set(COOKIE_UID, userId, {
          expires: Number(expiresIn),
          sameSite: "strict",
          secure: true,
        })

  Cookies.set(COOKIE_TOKEN, accessToken, {
    expires: Number(expiresIn),
    sameSite: "strict",
    secure: true,
  })
}

// Returns id if it exists, otherwise - 0
export const getFromCookiesUserId = (): number => {
  const id = Cookies.get(COOKIE_UID)
  if (id) {
    return id?.length > 0 ? parseInt(id) : 0
  } else {
    return 0
  }
}

export const getFromCookiesToken = (): string | undefined => Cookies.get(COOKIE_TOKEN)

export const removeAllCookies = (): void => {
  Cookies.remove(COOKIE_TOKEN)
  Cookies.remove(COOKIE_UID)
}
