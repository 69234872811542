import React, { lazy } from "react"
import { RouteProps } from 'react-router-dom';

import { isProduction } from 'config/env';
import { IStaffRole } from 'stores/redux/slices/staff/types';
import {
  allowedForAdmin,
  allowedForManager,
} from "variables/roles"
import { RouteNames } from "variables/routes"

const Article = lazy(() => import("routes/pages/article"));
const ClientCard = lazy(() => import("routes/pages/client-card"));
const Clients = lazy(() => import("routes/pages/clients"));
const CreateArticle = lazy(() => import("routes/pages/create-article"));
const CreateClient = lazy(() => import("routes/pages/create-client"));
const CreateLead = lazy(() => import("routes/pages/create-lead"));
const CreateUser = lazy(() => import("routes/pages/create-user"));
const Documents = lazy(() => import("routes/pages/documents"));
const DocumentsCheck = lazy(() => import("routes/pages/documents-check"));
const DocumentsRequired = lazy(() => import("routes/pages/documents-required"));
const EditArticle = lazy(() => import("routes/pages/edit-article"));
const EditClient = lazy(() => import("routes/pages/edit-client"));
const EditLead = lazy(() => import("routes/pages/edit-lead"));
const EditProfileInfo = lazy(() => import("routes/pages/edit-profile"));
const EditUserInfo = lazy(() => import("routes/pages/edit-user"));
const ErrorPage = lazy(() => import("routes/pages/error-page"));
const Home = lazy(() => import("routes/pages/home"));
const LeadCard = lazy(() => import("routes/pages/lead-card"));
const Leads = lazy(() => import("routes/pages/leads"));
const PasswordReset = lazy(() => import("routes/pages/password-reset"));
const PasswordRestore = lazy(() => import("routes/pages/password-restore"));
const Profile = lazy(() => import("routes/pages/profile"));
const References = lazy(() => import("routes/pages/references"));
const SignIn = lazy(() => import("routes/pages/sign-in"));
const Staff = lazy(() => import("routes/pages/staff"));
const UserCard = lazy(() => import("routes/pages/user-card"));
const Vacation = lazy(() => import("routes/pages/vacation"));
const Wiki = lazy(() => import("routes/pages/wiki"));


type TRouteBase = RouteProps & {
  element: JSX.Element;
  icon?: JSX.Element;
  id: RouteNames;
  index?: boolean;
  path?: string;
  permission?: IStaffRole[];
};

type TRoute = TRouteBase & {
  subroutes?: TRouteBase[];
};


/* dev */
export const devRoutes: TRoute[] = [];

export const authRoutes: TRoute[] = [
  {
    element: <PasswordReset />,
    id: RouteNames.PasswordReset,
    path: RouteNames.PasswordReset,
  },

  {
    element: <PasswordRestore />,
    id: RouteNames.PasswordRestore,
    path: RouteNames.PasswordRestore,
  },

  {
    element: <SignIn />,
    id: RouteNames.SignIn,
    path: RouteNames.SignIn,
  },
];

const publicRoutesArr: TRoute[] = [ {
  element: <ErrorPage />,
  id: RouteNames.Error,
  path: RouteNames.Error,
} ]

export const publicRoutes: TRoute[] = isProduction() ? publicRoutesArr : [ ...devRoutes, ...publicRoutesArr ];

export const privateRoutes: TRoute[] = [
  {
    element: <DocumentsCheck />,
    id: RouteNames.DocumentsCheck,
    path: RouteNames.DocumentsCheck,
  },

  {
    element: <ClientCard />,
    id: RouteNames.ClientCard,
    path: RouteNames.ClientCard,
    permission: allowedForManager,
  },

  {
    element: <Clients />,
    id: RouteNames.Clients,
    path: RouteNames.Clients,
    permission: allowedForManager,
  },

  {
    element: <CreateClient />,
    id: RouteNames.CreateClient,
    path: RouteNames.CreateClient,
    permission: allowedForManager,
  },

  {
    element: <CreateLead />,
    id: RouteNames.CreateLead,
    path: RouteNames.CreateLead,
    permission: allowedForManager,
  },

  {
    element: <CreateUser />,
    id: RouteNames.CreateUser,
    path: RouteNames.CreateUser,
    permission: allowedForAdmin,
  },

  {
    element: <CreateArticle />,
    id: RouteNames.CreateArticle,
    path: RouteNames.CreateArticle,
    permission: allowedForAdmin,
  },

  {
    element: <Documents />,
    id: RouteNames.Documents,
    path: RouteNames.Documents,
    permission: allowedForAdmin,
  },

  {
    element: <EditClient />,
    id: RouteNames.EditClient,
    path: RouteNames.EditClient,
    permission: allowedForManager,
  },

  {
    element: <EditLead />,
    id: RouteNames.EditLead,
    path: RouteNames.EditLead,
    permission: allowedForManager,
  },

  {
    element: <EditUserInfo />,
    id: RouteNames.EditUser,
    path: RouteNames.EditUser,
    permission: allowedForAdmin,
  },

  {
    element: <EditProfileInfo />,
    id: RouteNames.EditProfile,
    path: RouteNames.EditProfile,
    permission: allowedForAdmin,
  },

  {
    element: <EditArticle />,
    id: RouteNames.EditArticle,
    path: RouteNames.EditArticle,
    permission: allowedForAdmin,
  },

  {
    element: <Home />,
    id: RouteNames.Home,
    index: true,
    path: RouteNames.Home,
  },

  {
    element: <LeadCard />,
    id: RouteNames.Lead,
    path: RouteNames.Lead,
    permission: allowedForManager,
  },

  {
    element: <Leads />,
    id: RouteNames.Leads,
    path: RouteNames.Leads,
    permission: allowedForManager,
  },

  {
    element: <Profile />,
    id: RouteNames.Profile,
    path: RouteNames.Profile,
  },

  {
    element: <References />,
    id: RouteNames.References,
    path: RouteNames.References,
    permission: allowedForAdmin,
  },

  {
    element: <DocumentsRequired />,
    id: RouteNames.DocumentsRequired,
    path: RouteNames.DocumentsRequired,
  },

  {
    element: <Staff />,
    id: RouteNames.UserList,
    path: RouteNames.UserList,
  },

  {
    element: <UserCard />,
    id: RouteNames.User,
    path: RouteNames.User,
  },
  {
    element: <Vacation />,
    id: RouteNames.Vacation,
    path: RouteNames.Vacation,
  },
  {
    element: <Wiki />,
    id: RouteNames.Wiki,
    path: RouteNames.Wiki,
  },
  {
    element: <Article />,
    id: RouteNames.Article,
    path: RouteNames.Article,
  },
];

export const notFoundRoutes: TRoute[] = [ {
  element: <ErrorPage />,
  id: 'NOT_FOUND' as RouteNames,
} ];


