import { pathToRegexp } from 'path-to-regexp';

import { RouteNames } from "variables/routes"


export const checkIfRouteEquals = (route: string, pathname?: string) => {
  const isTest = pathToRegexp(route).test(pathname || window.location.pathname);

  return (
    (pathname || window.location.pathname) !== '' &&
    (pathname || window.location.pathname) !== RouteNames.Home
    && isTest
  );
};
