import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { IClientsData } from "models/IClientsData"


import { IClientsState } from "./types";

const initialState: IClientsState = {
  createResult: false,
  data: {
    clients: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  deleteResult: false,
  isLoading: false,
  newClientId: 0,
}

export const clientsSlice = createSlice({
  initialState,
  name: 'clients',
  reducers: {
    resetStoreClients(state: IClientsState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IClientsState]);
    },
    setClientsCreateId(state: IClientsState, action: PayloadAction<number>) {
      state.newClientId = action.payload
    },
    setClientsCreateResult(state: IClientsState, action: PayloadAction<boolean>) {
      state.createResult = action.payload
    },
    setClientsData(state: IClientsState, action: PayloadAction<{
            clients: Array<IClientsData>,
            pagination: {
                current_page: number,
                last_page: number,
                per_page: number,
                total: number
            }
        }>) {
      state.data = action.payload
    },
    setClientsDeleteResult(state: IClientsState, action: PayloadAction<boolean>) {
      state.deleteResult = action.payload
    },
    setClientsLoading(state: IClientsState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
});

export const {
  resetStoreClients,
  setClientsCreateId,
  setClientsCreateResult,
  setClientsData,
  setClientsDeleteResult,
  setClientsLoading,
} = clientsSlice.actions;

export default clientsSlice.actions;
