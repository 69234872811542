import React from 'react';

import clsx from "clsx"

import { Loader } from "common/components/loader/Loader"
import s from 'common/components/loader/Loader.module.scss'
import { ReactFCC } from 'common/types/react';

type TLoaderScreen = {
  className?: string,
  isNoAbsolute?: boolean,
}

export const LoaderScreen: ReactFCC<TLoaderScreen> = props => {

  const {
    className,
    isNoAbsolute,
  } = props;

  return (
    <Loader
      className={clsx(className, s.LoaderScreen__loader, isNoAbsolute && s.no_absolute)}
      withLabel
    />
  );
};
