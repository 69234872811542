import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { useLocation } from 'react-router';

import { ReactFCC } from 'common/types/react';


type THistoryCtxProvider = {
  state: string[];
};

export const HistoryCtx = createContext({} as unknown as THistoryCtxProvider);
export const useHistory = () => useContext(HistoryCtx);

export const HistoryCtxProvider: ReactFCC<unknown> = props => {
  const { children } = props;

  const { pathname } = useLocation();

  const [ currentPath, setCurrentPath ] = useState<string>(pathname);
  const [ state, setState ] = useState<string[]>([]);

  useEffect(() => {

    if (currentPath !== pathname) {
      setCurrentPath(pathname);
      setState([ ...state, pathname ]);
    }
  }, [
    currentPath,
    pathname,
    state,
  ]);

  const ctxValue: THistoryCtxProvider = useMemo(() => ({ state }), [ state ]);

  return (
    <HistoryCtx.Provider value={ctxValue}>
      {children}
    </HistoryCtx.Provider>
  );
};
