import {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"

import { useAuthService } from "api/hooks/useAuthService"
import { useCurrentUserService } from "api/hooks/useCurrentUserService"
import { useActions } from "common/hooks/useActions"
import { isNoAuth } from "config/env"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { LOCAL_STORAGE_KEY_AUTH_REDIRECT_AFTER } from 'variables/local-storage-keys';
import { RouteNames } from "variables/routes"

export const useUserData = () => {

  const navigate = useNavigate()

  const {
    auth: {
      isAuth: isAuthStore,
      isLoading,
    },
    currentUser: {
      email,
      id,
    },
  } = useTypedSelector(state => state)

  const {
    setAuthLoading,
    setUserVerified,
  } = useActions()

  const { fetchUserData } = useCurrentUserService()
  const { getUserRole } = useAuthService()

  const [ fetchedId, setFetchedId ] = useState<number | null>(null)
  const [ isAuth, setAuth ] = useState<boolean>(isAuthStore)
  const [ isUserRedirected, setUserRedirected ] = useState<boolean>(false);

  const redirectInit = useCallback(() => {
    if (!isUserRedirected) {
      const url = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT_AFTER);
      setUserRedirected(true);

      if (url) {
        navigate(url);
        localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT_AFTER);
        return;
      }

      navigate(RouteNames.Home)
    }
  }, [ isUserRedirected, navigate ])

  const fetchCurrentUserData = useCallback(async () => {
    if (!isLoading){
      setAuthLoading(true)
    }

    fetchUserData(id)
      .then(async res => {
        if (res){
          setUserVerified(true)
          await getUserRole(id, true)
          setAuthLoading(false)

          /**
           * Проверяем, хотел ли юзер перейти в какую-то конкретную точку приложения, когда открывал сайт.
           * Другими словами - по какой ссылке юзер перешел в приложение?
           * Например, юзер мог перейти в приложение по ссылке [www.site.com/],
           * а мог по [www.site.com/settings] - хотел попасть сразу в конкретную точку.
           */
          redirectInit()

          return;
        }

        if (!isNoAuth()){
          navigate(RouteNames.DocumentsRequired)
        }
      })
      .catch(() => {
        if (isNoAuth()){
          redirectInit()
        }
      })
  }, [
    fetchUserData,
    getUserRole,
    id,
    isLoading,
    navigate,
    redirectInit,
    setAuthLoading,
    setUserVerified,
  ])

  useEffect(() => {
    if (isAuthStore && !isAuth){
      setAuth(true)
      return;
    }

    if (!isAuthStore && isAuth){
      setAuth(false)
      setFetchedId(null)
    }
  }, [ isAuth, isAuthStore ])

  useEffect(() => {
    if (id && id !== 0 && !email && (!fetchedId || fetchedId !== id)) {
      fetchCurrentUserData()
      setFetchedId(id)
    }
  }, [
    email,
    fetchCurrentUserData,
    fetchedId,
    id,
  ])
}
