import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  TVacationsWidgetData,
  TWidgetsState,
  TWorkData, 
} from "stores/redux/slices/widgets/types"


const initialState: TWidgetsState = {
  isVacationWidgetLoading: false,
  isWorkWidgetLoading: false,
  vacationWidgetData: null,
  workWidgetData: null,
}

export const widgetsSlice = createSlice({
  initialState,
  name: 'widgets',
  reducers: {
    resetStoreWidgets(state: TWidgetsState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof TWidgetsState]);
    },
    setVacationWidgetCleanData(state: TWidgetsState) {
      state.vacationWidgetData = initialState.vacationWidgetData
    },
    setVacationWidgetData(state: TWidgetsState, action: PayloadAction<TVacationsWidgetData[]>) {
      state.vacationWidgetData = action.payload
    },
    setVacationWidgetLoading(state: TWidgetsState, action: PayloadAction<boolean>) {
      state.isVacationWidgetLoading = action.payload
    },
    setWorkWidgetCleanData(state: TWidgetsState) {
      state.workWidgetData = initialState.workWidgetData
    },
    setWorkWidgetData(state: TWidgetsState, action: PayloadAction<TWorkData>) {
      state.workWidgetData = action.payload
    },
    setWorkWidgetLoading(state: TWidgetsState, action: PayloadAction<boolean>) {
      state.isWorkWidgetLoading = action.payload
    },
  },
});

export const {
  resetStoreWidgets,
  setVacationWidgetCleanData,
  setVacationWidgetData,
  setVacationWidgetLoading,
  setWorkWidgetCleanData,
  setWorkWidgetData,
  setWorkWidgetLoading,
} = widgetsSlice.actions;

export default widgetsSlice.actions;
