import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ReactFCC } from 'common/types/react';
import { ErrorFallbackCritical } from 'routes/wrappers/ErrorBoundary/critical/fallback/ErrorFallbackCritical';
import { errorBoundaryHandler } from 'routes/wrappers/ErrorBoundary/error-handler';

/**
 * Catches app bugs
 *
 * !!! Be careful here. This "Boundary" is higher than all other app wrappers,
 * so various hooks will not work here (useDispatch, etc...).
 *
 * @param {{children?: React.ReactNode}} props
 * @component {React.FC} ErrorBoundaryCritical
 * @return {JSX.Element} children wrapped with "ErrorBoundaryCritical"
 */
export const ErrorBoundaryCritical: ReactFCC = props => {
  const { children } = props;
  
  return (
    // @ts-ignore
    <ErrorBoundary
      FallbackComponent={ErrorFallbackCritical}
      onError={errorBoundaryHandler}
    >
      {children}
    </ErrorBoundary>
  );
};
