import { useCallback } from 'react';


import { useTypedDispatch } from 'stores/redux/hooks/useTypedDispatch';
import { setAppError } from 'stores/redux/slices/app';
import { consoleError } from 'utils/console';
import {
  getMessageFromResponse,
  TGetMessageFromResponseData,
} from 'utils/messages';


type TProcessResponseErrorWithData = {
  data: TGetMessageFromResponseData | unknown;
}

type TProcessResponseErrorWithMessageString = {
  code?: string;
  error?: string;
  status?: string;
}

type TProcessResponseErrorWithMessageObj = {
  code?: string;
  error?: {
    message?: string;
  };
  status?: string;
}

/**
 * Т.к. на бэке у нас хаос, то здесь мы пытаемся угадать,
 * какой ответ и в какой форме придет
 */
type TProcessResponseError =
  TProcessResponseErrorWithData
  | TProcessResponseErrorWithMessageString
  | TProcessResponseErrorWithMessageObj

export const useProcessResponseError = () => {

  const dispatch = useTypedDispatch()

  const processResponseError = useCallback((response: TProcessResponseError) => {
    if (!response){
      consoleError({ message: `
      Error in: "useProcessResponseError" hook "processResponseError" function
      Expected: (response: TProcessResponseError)
      Received: ${response}
      ` });

      return;
    }

    const data: TGetMessageFromResponseData | unknown | undefined = (response as TProcessResponseErrorWithData).data

    const errMessages = data && typeof data === 'object' ? getMessageFromResponse(data as TGetMessageFromResponseData)?.filter(message => typeof message === 'string') : undefined

    if (Array.isArray(errMessages) && errMessages.length){
      errMessages.forEach(message => dispatch(setAppError(message)))
      return;
    }

    let err = 'Что-то пошло не так'

    const resErrObj = (response as TProcessResponseErrorWithMessageObj).error
    const resErrStr = (response as TProcessResponseErrorWithMessageString).error

    if (typeof resErrObj === 'object' && resErrObj.message){
      err = resErrObj.message
    } else if (typeof resErrStr === 'string'){
      err = resErrStr
    }

    let errMessage = err
    const code = (response as TProcessResponseErrorWithMessageString).code || (response as TProcessResponseErrorWithMessageString).status

    if (code){
      errMessage = `${err}. Код ошибки: ${code}.`
    }

    dispatch(setAppError(errMessage))
  }, [ dispatch ])

  return { processResponseError };
}
