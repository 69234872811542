import React from 'react'
import { FC } from "react"

import { Pagination } from "antd"

import { TStaffList } from 'api/hooks/useStaffService/useStaffService.types';
import { Loader } from "common/components/loader/Loader"
import styles from "common/components/staff/cardView/CardView.module.scss"
import { UserList } from "common/components/staff/cardView/UserList"


interface ICardViewProps {
    data: TStaffList
    fetchData: (page: number, per_page?: number) => void
    loading: boolean
}

export const CardView: FC<ICardViewProps> = props => {
  const { data: {
    pagination,
    users,
  } } = props

  return (
    <div className={styles.container}>
      {
        props.loading ? (
          <div className={styles.loading}>
            <Loader />
          </div>
        ) : (
          <UserList users={users} />
        )
      }

      <Pagination
        current={pagination.current_page}
        defaultCurrent={1}
        defaultPageSize={15}
        onChange={(page, per_page) => props.fetchData(page, per_page)}
        onShowSizeChange={(page, per_page) => props.fetchData(page, per_page)}
        pageSizeOptions={[ "15", "30" ]}
        showSizeChanger={true}
        total={pagination.total}
      />
    </div>
  )
}

