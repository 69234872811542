import React from 'react'
import {
  FC,
  useEffect,
  useState,
} from "react"

import { Avatar } from "antd"

import { UserOutlined } from "@ant-design/icons"
import { FilesService } from "api/FilesService"
import { TStaffListItemPersonal } from 'api/hooks/useStaffService/useStaffService.types';

const getAvatarImage = async (avatarId: number) => {
  try {
    const imageResponse = await FilesService.getFile(avatarId, "blob")
    return imageResponse.status === 200
      ? URL.createObjectURL(imageResponse.data)
      : undefined
  } catch (e) {
    return undefined
  }
}

interface IAvatarProps {
    personal: TStaffListItemPersonal
}

export const AvatarComponent: FC<IAvatarProps> = props => {
  const avatarId = props.personal?.avatar?.id || 0

  const [ imageSrc, setImageSrc ] = useState<string | undefined>(undefined)
  const [ isRequested, setRequested ] = useState<boolean>(false)

  useEffect(() => {
    if (!isRequested && !imageSrc && avatarId !== 0) {
      getAvatarImage(avatarId).then(imgSrs => setImageSrc(imgSrs))
      return () => setRequested(true)
    }
  }, [
    isRequested,
    imageSrc,
    avatarId,
  ])

  return (
    <Avatar
      icon={<UserOutlined />}
      src={imageSrc}
    />
  )
}
