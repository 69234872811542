import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  ILeadReason,
  ILeadSource,
  ILeadStatus,
} from "stores/redux/slices/leads/types"
import {
  IProjectsLabels,
  IReferencesState,
} from "stores/redux/slices/options-references/types"

const initialState: IReferencesState = {
  error: "",
  isLoading: false,
  isProjectLoading: false,
  isProjectUpdate: false,
  isUserLoading: false,
  isUserUpdate: false,
  projectLabels: [],
  reasons: [],
  sources: [],
  statuses: [],
  userCompetencies: [],
  userLevels: [],
  userPositions: [],
}

export const optionsReferencesSlice = createSlice({
  initialState,
  name: 'options-content',
  reducers: {
    addLeadReason(state: IReferencesState, action: PayloadAction<ILeadReason>) {
      state.reasons = [ ...state.reasons, action.payload ]
    },
    addLeadSource(state: IReferencesState, action: PayloadAction<ILeadSource>) {
      state.sources = [ ...state.sources, action.payload ]
    },
    addLeadStatus(state: IReferencesState, action: PayloadAction<ILeadStatus>) {
      state.statuses = [ ...state.statuses, action.payload ]
    },
    deleteLeadReason(state: IReferencesState, action: PayloadAction<number>) {
      state.reasons = [ ...state.reasons.filter(el => el.id !== action.payload) ]
    },
    deleteLeadSource(state: IReferencesState, action: PayloadAction<number>) {
      state.sources = [ ...state.sources.filter(el => el.id !== action.payload) ]
    },
    deleteLeadStatus(state: IReferencesState, action: PayloadAction<number>) {
      state.statuses = [ ...state.statuses.filter(el => el.id !== action.payload) ]
    },
    resetStoreOptionsReferences(state: IReferencesState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IReferencesState]);
    },
    setEditProjectsReferences(state: IReferencesState, action: PayloadAction<boolean>) {
      state.isProjectUpdate = action.payload
    },
    setEditUsersReferences(state: IReferencesState, action: PayloadAction<boolean>) {
      state.isUserUpdate = action.payload
    },
    setLeadReferences(state: IReferencesState, action: PayloadAction<{
      reasons: Array<ILeadReason>,
      sources: Array<ILeadSource>
      statuses: Array<ILeadStatus>
    }>) {
      state.reasons = action.payload.reasons
      state.sources = action.payload.sources
      state.statuses = action.payload.statuses
    },
    setLoadingProjectsReferences(state: IReferencesState, action: PayloadAction<boolean>) {
      state.isProjectLoading = action.payload
    },
    setLoadingUsersReferences(state: IReferencesState, action: PayloadAction<boolean>) {
      state.isUserLoading = action.payload
    },
    setProjectsLabelsReferences(state: IReferencesState, action: PayloadAction<IProjectsLabels[]>) {
      state.projectLabels = action.payload
    },
    setReferencesError(state: IReferencesState, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setReferencesLoading(state: IReferencesState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setUsersReferences(state: IReferencesState, action: PayloadAction<{
      userCompetencies: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
      userLevels: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
      userPositions: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
    }>) {
      state.userCompetencies = action.payload.userCompetencies
      state.userLevels = action.payload.userLevels
      state.userPositions = action.payload.userPositions
    },
    updateLeadReason(state: IReferencesState, action: PayloadAction<ILeadReason>) {
      const updated = [ ...state.reasons ]
      const index = updated.findIndex(el => el.id === action.payload.id)
      updated[index] = action.payload
      state.reasons = updated
    },
    updateLeadSource(state: IReferencesState, action: PayloadAction<ILeadSource>) {
      const updated = [ ...state.sources ]
      const index = updated.findIndex(el => el.id === action.payload.id)
      updated[index] = action.payload
      state.sources = updated
    },
    updateLeadStatus(state: IReferencesState, action: PayloadAction<ILeadStatus>) {
      const updated = [ ...state.statuses ]
      const index = updated.findIndex(el => el.id === action.payload.id)
      updated[index] = action.payload
      state.statuses = updated
    },
  },
});

export const {
  addLeadReason,
  addLeadSource,
  addLeadStatus,
  deleteLeadReason,
  deleteLeadSource,
  deleteLeadStatus,
  resetStoreOptionsReferences,
  setEditProjectsReferences,
  setEditUsersReferences,
  setLeadReferences,
  setLoadingProjectsReferences,
  setLoadingUsersReferences,
  setProjectsLabelsReferences,
  setReferencesError,
  setReferencesLoading,
  setUsersReferences,
  updateLeadReason,
  updateLeadSource,
  updateLeadStatus,
} = optionsReferencesSlice.actions;

export default optionsReferencesSlice.actions;
