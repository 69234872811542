import React from 'react';

import { App } from "app/App"
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render((
  <React.StrictMode>
    <App />
  </React.StrictMode>
));
