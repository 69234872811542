import React, { Suspense } from "react"
import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Layout } from "antd"

import { Sidebar } from "common/components/aside/Sider"
import { Header } from "common/components/header/Header"
import { LoaderScreen } from "common/components/loader/LoaderScreen"
import { useAuthChecker } from "common/hooks/useAuthChecker"
import { useIsPage } from "common/hooks/useIsPage"
import { isNoAuth } from "config/env"
import styles from "routes/layouts/app-layout/index.module.scss"
import { Breadcrumbs } from "routes/layouts/breadcrumbs"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"


export const AppLayout: FC = () => {

  const {
    auth: { isAuth },
    currentUser: { isVerified },
    optionsDocuments: { isLoading: isDocumentsLoading },
  } = useTypedSelector(s => s)

  const { isAuthLoading } = useAuthChecker()
  const { isAuthRoutePage } = useIsPage()

  if ((isAuth && isVerified) || isNoAuth()){
    return (
      <>
        {(isAuthLoading && !isNoAuth()) && <LoaderScreen />}

        <Layout>
          <Header />

          <Layout className={styles.mainPart}>
            <Sidebar />

            <Layout.Content className={styles.content}>
              <Breadcrumbs />

              <Suspense fallback={<LoaderScreen isNoAbsolute />}>
                <Outlet />
              </Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      </>
    );
  }

  const isLoaderPublic =
    !isAuthRoutePage
    && !isAuthLoading
    && isDocumentsLoading

  return (
    <div className={styles.AppLayout__wrapper_default}>
      {isLoaderPublic && <LoaderScreen />}

      <Suspense fallback={<LoaderScreen isNoAbsolute />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
