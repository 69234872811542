
export const setDataInStorage = (name: string, data: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  const stringData = JSON.stringify(data)

  localStorage.setItem(name, stringData)
}

export const getDataFromStorage = (name: string) => {
  const data = localStorage.getItem(name)

  return data ? JSON.parse(data) : null
}
