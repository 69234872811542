import {
  useEffect,
  useState,
} from "react"
import {
  useLocation,
  useNavigate,
} from "react-router-dom"

import { useActions } from "common/hooks/useActions"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import {
  getFromCookiesUserId,
  removeAllCookies,
} from "utils/cookies"
import { isLoggedIn } from "utils/isLoggedIn"
import { RouteNames } from "variables/routes"


export const useAuthChecker = () => {

  const {
    auth: {
      error: authError,
      isAuth,
      isLoading: isAuthLoading,
    },
    currentUser: { id: currentId },
  } = useTypedSelector(s => s)

  const [ userCurrentId, setUserCurrentId ] = useState(currentId)

  const {
    setIsAuth,
    setUserId,
  } = useActions()

  const location = useLocation()
  const navigate = useNavigate()

  const isOnSignInPage = location.pathname === RouteNames.SignIn
  const isOnResetPasswordPage = location.pathname === RouteNames.PasswordReset
  const isOnRestorePasswordPage = location.pathname === RouteNames.PasswordRestore

  const onAuth = isOnSignInPage || isOnRestorePasswordPage || isOnResetPasswordPage

  const userId = getFromCookiesUserId()
  const isToken = isLoggedIn()

  useEffect(() => {
    if (userId && isToken) {
      if (currentId !== userId) {
        setUserId(userId)
        setUserCurrentId(userId)
      }

      if (!isAuth) {
        setIsAuth(true)
      }

      if (!isAuth && authError) {
        removeAllCookies()
        setIsAuth(false)
      }
      return;
    }

    if (isAuth) {
      setIsAuth(false)
    }

    if (!onAuth) {
      navigate(RouteNames.SignIn)
    }
  }, [
    isAuth,
    location.pathname,
    userId,
    isToken,
    currentId,
    authError,
    setUserId,
    setIsAuth,
    onAuth,
    navigate,
  ])

  return {
    isAuthLoading,
    loggedIn: isAuth,
    userCurrentId,
  }
}
