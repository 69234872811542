import React from 'react';
import {
  useLocation,
  Navigate,
} from 'react-router-dom';

import { LoaderScreen } from "common/components/loader/LoaderScreen"
import { useIsPage } from "common/hooks/useIsPage"
import { usePermissions } from 'common/hooks/usePermissions';
import { isNoAuth } from 'config/env';
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { RouteNames } from "variables/routes"

type TRequireAuth = {
  children: JSX.Element;
};

export const AuthGuard = (props: TRequireAuth) => {

  const { children } = props;

  const {
    isAdmin,
    isManagerOnly,
  } = usePermissions()

  const {
    isDocumentsCheckPage,
    isDocumentsRequiredPage,
    isForEveryonePage,
    isOnlyForAdminPage,
    isOpenForManagerPage,
  } = useIsPage()

  const {
    auth: {
      isAuth,
      isLoading,
    },
    currentUser: { isVerified },
  } = useTypedSelector(store => store)

  const location = useLocation();

  const isDocsPage = isAuth && (isDocumentsCheckPage || isDocumentsRequiredPage)

  if (isLoading && !isDocsPage && !isNoAuth()){
    return <LoaderScreen />;
  }

  if (!isNoAuth() && !isAuth) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={RouteNames.SignIn}
      />
    );
  }

  if (!isNoAuth() && !isVerified && !isDocsPage) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={RouteNames.DocumentsRequired}
      />
    );
  }

  if (
    !isForEveryonePage &&
    (
      (isManagerOnly && !isOpenForManagerPage)
      || (!isAdmin && isOnlyForAdminPage)
    )
  ){
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={RouteNames.Home}
      />
    );
  }

  return children;
};
