import React, {
  useCallback,
  useRef,
} from 'react';
import {
  FC,
  useEffect,
} from "react"
import { useNavigate } from "react-router-dom"

import { notification } from "antd"

import { useActions } from "common/hooks/useActions"
import { useIsPage } from "common/hooks/useIsPage"
import { isNoAuth } from "config/env"
import { useResetStores } from "stores/redux/hooks/useResetStores"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { removeAllCookies } from "utils/cookies"
import { RouteNames } from "variables/routes"

export const NotificationHandler: FC<{ children: React.ReactNode }> = ({ children }) => {

  const { reset } = useResetStores()

  const {
    isDocumentsCheckPage,
    isDocumentsRequiredPage,
    isErrorPage,
    isLoginPage,
  } = useIsPage()

  const {
    setAppError,
    setAppGlobalError,
    setAppMessage,
    setAppSuccess,
    setAuthError,
  } = useActions()

  const {
    app: {
      error,
      errorMessage,
      globalError,
      success,
    },
    auth: {
      isAuth,
      isLoading: isAuthLoading,
    },
  } = useTypedSelector(state => state)

  const navigate = useNavigate()

  const pushToErrorPage = useCallback((status: number, message: string) => {
    if (!isErrorPage && !isNoAuth()){
      navigate(RouteNames.Error, { state: {
        message,
        status,
      } })
    }

    setAppGlobalError({
      message: [ "" ],
      status: 0,
    })
  }, [
    isErrorPage,
    navigate,
    setAppGlobalError,
  ])

  const shownNotificationsMessages = useRef<string[]>([])

  const checkNotificationExists = useCallback((message: string) => shownNotificationsMessages?.current?.find(item => item === message), [ shownNotificationsMessages ])

  const addNotification = useCallback((message: string) => {
    shownNotificationsMessages.current = [ ...(shownNotificationsMessages.current || []), message.toLowerCase() ]
  }, [ shownNotificationsMessages ])

  const removeNotification = useCallback((message: string) => shownNotificationsMessages?.current?.filter(m => m !== message), [ shownNotificationsMessages ])

  const openErrorNotification = useCallback((message: string): void => {
    notification.open({
      description: message,
      duration: 6,
      message: "Ошибка!",
      onClose: () => removeNotification(message),
      type: "error",
    })
  }, [ removeNotification ])

  const openSuccessNotification = useCallback((message: string): void => {
    notification.open({
      description: message,
      duration: 6,
      message: "Успех!",
      type: "success",
    })
  }, [])

  const openInfoNotification = useCallback((message: string): void => {
    notification.open({
      description: message,
      duration: 6,
      message: "Внимание!",
      type: "info",
    })
  }, [])


  const globalErrorHandler = useCallback((err: {
    errorMessage: Array<string>,
    status: number
  }) => {
    const message401 = "Время сессии истекло"
    switch (err.status) {
      case 401:
        setAuthError(message401)
        openErrorNotification(message401)
        removeAllCookies()
        reset()

        if (!isLoginPage){
          navigate(RouteNames.SignIn)
        }

        break
      case 403:
        const isNotVerified = err.errorMessage.find(message => message.toLowerCase().includes("usernotverified"))

        if (isNotVerified){
          if (!isDocumentsRequiredPage && !isDocumentsCheckPage){
            navigate(RouteNames.DocumentsRequired)
          }

          break
        }

        pushToErrorPage(403, "У вас недостаточно прав доступа для просмотра этой страницы.")
        break
      case 404:
        pushToErrorPage(404, "Запрашиваемое действие или ресурс не найдены.")
        break
      case 500:
        pushToErrorPage(500, "Ошибка при обработке запроса. Повторите попытку позже.")
        break
      default:
        err.errorMessage.forEach(message => {
          if (message) {
            openErrorNotification(message)
          } else {
            openErrorNotification("Ошибка при обработке запроса, повторите попытку позже.")
          }
        })
        setAppGlobalError({
          message: [ "" ],
          status: 0,
        })
        break
    }
  }, [
    isDocumentsCheckPage,
    isDocumentsRequiredPage,
    isLoginPage,
    navigate,
    openErrorNotification,
    pushToErrorPage,
    reset,
    setAppGlobalError,
    setAuthError,
  ])

  useEffect(() => {
    if (error) {
      /**
       * Пока отслеживаем только дублирование ошибки 'unauthorized'
       */
      const errLowerCased = error?.toLowerCase()
      if (typeof errLowerCased === 'string' && errLowerCased === 'unauthorized'){
        if (!checkNotificationExists(errLowerCased)){
          addNotification(errLowerCased)
          openErrorNotification('Ошибка авторизации')
          setAppError("")
        }

        return;
      }

      openErrorNotification(error)
      setAppError("")
    }
  }, [
    addNotification,
    checkNotificationExists,
    error,
    openErrorNotification,
    setAppError,
  ])

  useEffect(() => {
    if (success) {
      openSuccessNotification(success)
      setAppSuccess("")
    }
  }, [
    success,
    setAppSuccess,
    openSuccessNotification,
  ])

  useEffect(() => {
    if (errorMessage) {
      openInfoNotification(errorMessage)
      setAppMessage("")
    }
  }, [
    errorMessage,
    openInfoNotification,
    setAppMessage,
  ])

  useEffect(() => {
    if (globalError.status !== 0) {
      globalErrorHandler(globalError)
    }
  }, [ globalError, globalErrorHandler ])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
