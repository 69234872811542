import React, { useLayoutEffect } from "react"
import {
  useLocation,
  Navigate,
} from 'react-router-dom';

import { useActions } from "common/hooks/useActions"
import { ReactFCC } from 'common/types/react';
import { isNoAuth } from 'config/env';
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { RouteNames } from "variables/routes"


type TAuthRoutesWrapper = {
  children: JSX.Element;
};


/**
 * Следит за тем, чтобы при наличии авторизационных данных
 * на роутах авторизации происходил редирект с них на основные страницы
 */
export const AuthRoutesWrapper: ReactFCC<TAuthRoutesWrapper> = props => {

  const { children } = props;

  const {
    isAuth,
    isLoading,
  } = useTypedSelector(s => s.auth);

  const { setAuthLoading } = useActions()

  const location = useLocation();

  useLayoutEffect(() => {
    if (!isNoAuth() && !isAuth && isLoading){
      setAuthLoading(false)
    }
  }, [
    isAuth,
    isLoading,
    setAuthLoading,
  ])


  if (!isNoAuth() && !isAuth) {
    return children;
  }

  return (
    <Navigate
      replace
      state={{ from: location }}
      to={RouteNames.Home}
    />
  );
};
