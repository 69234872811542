import { sanitizeUrl } from '@braintree/sanitize-url';
import { consoleError } from 'utils/console';


/**
 * Validates internal link
 * @function { (url: string) => boolean } validateInternalLink
 *
 * @param { string } url
 *
 * @return {boolean} is link internal;
 */
export const validateInternalLink = (url: string): boolean => {
  if (!url || typeof url !== 'string') {
    consoleError({ message: `
    Error in: "validateInternalLink" function
    Expected: [url: string]
    Received: ${url}
    ` });

    return false;
  }

  return url.startsWith('/');
};

const showDefaultValidateExternalURLErr = (url = '') => consoleError({ message: `
    Error in: "validateExternalURL" function
    Expected: [url: string]
    Received: ${url}
    ` });

/**
 * Validates external link
 * @function { (url: string) => string } validateExternalURL
 *
 * @param { string } url
 *
 * @return {string} sanitized link or empty string;
 */
export const validateExternalURL = (url: string): string => {
  if (!url || typeof url !== 'string') {
    showDefaultValidateExternalURLErr(url);
    return '';
  }

  if (validateInternalLink(url)) {
    consoleError({ message: `
    Error in: "validateExternalURL" function
    Current link is not external
    Received: ${url}
    ` });

    return '';
  }

  const sanitizedUrl = sanitizeUrl(url);

  if (sanitizedUrl === 'about:blank') {
    showDefaultValidateExternalURLErr(url);
    return '';
  }

  const isMailTo = sanitizedUrl.startsWith('mailto');
  const isValidHttp = sanitizedUrl.startsWith('http:');
  const isValidHttps = sanitizedUrl.startsWith('https:');

  if (isMailTo || isValidHttp || isValidHttps) {
    return sanitizedUrl;
  }

  showDefaultValidateExternalURLErr(url);
  return '';
};

type TCreateNewTabUrlWithPath = {
  path: string;
};

export const openPathInSameTab = (props: TCreateNewTabUrlWithPath) => {
  const { path } = props;

  if (!path || typeof window !== 'object' || !window.location?.origin) {
    consoleError({ message: `
      Error in: "openPathInSameTab" function
      Received: path = ${path}
      [typeof window !== 'object'] = ${typeof window !== 'object'}
      [!window.location?.origin] = $!window.location?.origin}
      ` });

    return;
  }

  window.open(window.location.origin + path, '_self');
};

export const openPathInNewTab = (props: TCreateNewTabUrlWithPath) => {
  const { path } = props;

  if (!path || typeof window !== 'object' || !window.location?.origin) {
    consoleError({ message: `
      Error in: "openPathInNewTab" function
      Received: path = ${path}
      [typeof window !== 'object'] = ${typeof window !== 'object'}
      [!window.location?.origin] = $!window.location?.origin}
      ` });

    return;
  }

  window.open(path, '_blank');
};
