import React from 'react'
import { FC } from "react"
import { Navigate } from "react-router"

import { isNoAuth } from 'config/env';
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { IStaffRole } from 'stores/redux/slices/staff/types';
import { RouteNames } from "variables/routes"

/**
 * @param allowedRoles - разрешенные роли для обертываемой компоненты
 * @param showResult - показывать компоненту Result с сообщением об отсутствии прав доступа
 * @param children - обертываемая компонента
 */
interface IRolesWrapperProps {
    allowedRoles: Array<IStaffRole>
    children: React.ReactNode,
    showEror: boolean
}

/**
 *  Компонента-обертка, предназначена для проверки роли пользователя
 */
export const RolesWrapper: FC<IRolesWrapperProps> = props => {

  const {
    allowedRoles,
    children,
    showEror,
  } = props

  const roles = useTypedSelector(state => state.auth.roles)

  const isRenderChildren = roles.length > 0
    && allowedRoles.find(role => roles.find(r => r.id === role.id))

  if (isNoAuth()){
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (roles.length > 0){
    if (isRenderChildren){
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
    }

    if (showEror){
      return (
        <Navigate
          state={
            {
              message: "У вас недостаточно прав доступа для просмотра этой страницы.",
              status: 403,
            }
          }
          to={RouteNames.Error}
        />
      );
    }
  }

  return null
}
