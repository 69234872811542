import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  IAvatar,
  IUserData,
} from "models/IUserData"
import { ICurrentUserState } from "stores/redux/slices/current-user/types"

const initialState: ICurrentUserState = {
  avatar: {
    id: 0,
    url: "",
  },
  birth_date: "",
  changePasswordResult: false,
  contacts: [],
  email: "",
  first_name: "",
  id: 0,
  isVerified: false,
  middle_name: "",
  note: "",
  requisites: null,
  residence_address: "",
  resume_link: null,
  second_name: "",
  specialization: undefined,
}

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    resetStoreUser(state: ICurrentUserState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof ICurrentUserState]);
    },
    setMyData(state: ICurrentUserState, action: PayloadAction<IUserData>) {
      const newData = {
        ...state,
        ...action.payload,
      }
  
      Object.keys(newData).forEach(key => (state as unknown as { [k in string]: unknown })[key] = newData[key as keyof ICurrentUserState]);
    },
    setUserAvatar(state: ICurrentUserState, action: PayloadAction<IAvatar>) {
      state.avatar = action.payload
    },
    setUserChangePassword(state: ICurrentUserState, action: PayloadAction<boolean>) {
      state.changePasswordResult = action.payload
    },
    setUserId(state: ICurrentUserState, action: PayloadAction<number>) {
      state.id = action.payload
    },
    setUserVerified(state: ICurrentUserState, action: PayloadAction<boolean>) {
      state.isVerified = action.payload
    },
  },
});


export const {
  resetStoreUser,
  setMyData,
  setUserAvatar,
  setUserChangePassword,
  setUserId,
  setUserVerified,
} = userSlice.actions;

export default userSlice.actions;
