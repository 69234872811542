import { consoleError } from 'utils/console';

export type TGetMessageFromResponseData = Record<string, string | string[] | unknown>

export const getMessageFromResponse = (data: TGetMessageFromResponseData): string[] => {

  if (!data || typeof data !== 'object'){
    consoleError({ message: `
    Error in: "getMessageFromResponse" function
    Expected: (data: Record<string, unknown>)
    Received: ${data}
    ` });

    return [];
  }

  const messages = data ? Object.values(data) : []

  if (!messages?.length){
    return [];
  }

  const messagesArrStr: string[] = []

  messages.forEach(item => {
    if (typeof item === 'string'){
      messagesArrStr.push(item)
      return;
    }

    if (Array.isArray(item)){
      const itemArr = (item as unknown[])

      if (itemArr.length){
        itemArr.forEach(itemInner => {
          if (typeof itemInner === 'string'){
            messagesArrStr.push(itemInner)
          }
        })
      }
    }
  })

  return messagesArrStr
}
