import {
  Modal,
  ModalFuncProps, 
} from 'antd';

const { confirm } = Modal

export const enableHorizontalScrolling = () => {
  const body = document.getElementById("root")
  if (body) {
    body.classList.add("stopHorizontalScroll")
  }
}

export const disableHorizontalScrolling = () => {
  const body = document.getElementById("root")
  if (body) {
    body.classList.remove("stopHorizontalScroll")
  }
}

export const showDeleteConfirmOld = (
  onOkHandler: () => void,
  centered = false,
  title = "Вы уверены?",
  content = "Это действие необратимо.",
  okText = "Да",
  okType: "default" | "primary" | "ghost" | "dashed" | "link" | "text" | "danger" = "danger",
  cancelText = "Нет",
  onCancelHandler?: () => void,
  // eslint-disable-next-line max-params
) => {
  confirm({
    cancelText,
    centered,
    content,
    okText,
    okType,
    onCancel() {
      if (onCancelHandler) {
        onCancelHandler()
      }
    },
    onOk() {
      onOkHandler()
    },
    title,
    width: 350,
  })
}

export const showDeleteConfirm = (props: ModalFuncProps) => {

  const {
    cancelText = "Нет",
    centered = false,
    content = "Это действие необратимо.",
    okText = "Да",
    okType = "danger",
    title = "Вы уверены?",
    ...rest
  } = props

  confirm({
    cancelText,
    centered,
    content,
    okText,
    okType,
    title,
    width: 350,
    ...rest,
  })
}
