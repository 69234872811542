/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { getToken } from "../../utils/cookies"
import { URL_API } from "../urlList"

// eslint-disable-next-line max-params
export const axiosConfigPrivate = (method, url, data) => {
  const config = {
    baseURL: URL_API,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
    method,
    url,
    validateStatus: status => status > 0,
  }

  const configData = {
    baseURL: URL_API,
    data,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
    method,
    url,
    validateStatus: status => status > 0,
  }
  return data !== undefined ? configData : config
}

// eslint-disable-next-line max-params
export const axiosConfigFile = (method, url, data) => {
  return {
    baseURL: URL_API,
    data,
    headers: {
      Authorization: getToken(),
      "Content-Type": "multipart/form-data",
    },
    method,
    url,
    validateStatus: status => status > 0,
  }
}

// eslint-disable-next-line max-params
export const axiosConfigPublic = (method, url, data) => {
  const config = {
    method,
    url,
    validateStatus: status => status > 0,
  }

  const configData = {
    data,
    method,
    url,
    validateStatus: status => status > 0,
  }

  return data !== undefined ? configData : config
}
