import { useMemo } from "react"

import axios, { AxiosResponse } from "axios"

import {
  axiosConfigPrivate,
  axiosConfigPublic,
} from "api/config"
import {
  URL_AUTHENTICATION_LOGIN,
  URL_AUTHENTICATION_LOGOUT,
  URL_AUTHORIZATION,
  URL_RESET_PASSWORD_EMAIL,
} from "api/urlList"
import { useActions } from "common/hooks/useActions"
import { IAuthData } from "models/IAuthData"
import {
  IResetPasswordData,
  IRestorePasswordData,
} from "models/IRestorePasswordData"
import { useResetStores } from 'stores/redux/hooks/useResetStores';
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { IStaffRole } from 'stores/redux/slices/staff/types';
import {
  removeAllCookies,
  setTokens,
} from "utils/cookies"
import { getMessageFromResponse } from "utils/messages"
import { roleEmployee } from 'variables/roles';

export const useAuthService = () => {

  const {
    resetStoreUser,
    setAppGlobalError,
    setAuthError,
    setAuthLoading,
    setIsAuth,
    setRole,
    setUserId,
  } = useActions()

  const { reset: resetStores } = useResetStores()

  const { auth: { isLoading: isAuthLoading } } = useTypedSelector(state => state)

  const actions = useMemo(() => ({
    getRole: async (userId: number): Promise<AxiosResponse> => {
      return axios.get(`${URL_AUTHORIZATION}/${userId}/roles`,
        axiosConfigPrivate())
    },
    logout: async (): Promise<AxiosResponse> => {
      return axios.post(URL_AUTHENTICATION_LOGOUT, null, axiosConfigPrivate())
    },

    patchUserRoles: async (userId: string, roles: number[]): Promise<AxiosResponse> => {
      const body = JSON.stringify({ role_ids: roles })
      return axios.patch(`${URL_AUTHORIZATION}/${userId}/roles`, body, axiosConfigPrivate())
    },

    resetPassword: async (data: IResetPasswordData): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)

      return axios.post(URL_RESET_PASSWORD_EMAIL, body, axiosConfigPublic())
    },

    /* eslint-disable max-params */
    restorePassword: async (data: IRestorePasswordData,
      token: string,
      token2: string): Promise<AxiosResponse> => {
      /* eslint-enable max-params */
      const bodyData = {
        fghgcx: token2,
        password: data.password,
        password_confirmation: data.passwordRepeat,
        token: token,
      }
      const body = JSON.stringify(bodyData)

      return axios.put(URL_RESET_PASSWORD_EMAIL, body, axiosConfigPublic())
    },

    signIn: async (data: IAuthData): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)

      return axios.post(URL_AUTHENTICATION_LOGIN, body, axiosConfigPublic())
    },
  }), [])

  return {
    getUserRole: async (id: number, isCurrent = false) => {
      try {
        const response = await actions.getRole(id)

        if (response.status === 200 || response.status === 201) {
          if (response.data.length > 0) {
            const roles = (response.data as IStaffRole[]) || []

            if (!roles.find(r => r.name === roleEmployee.name)) {
              roles.push(roleEmployee)
            }

            if (isCurrent){
              setRole(roles)
              return;
            }

            return roles;
          } else {
            if (isCurrent){
              setRole([ roleEmployee ])
              return;
            }

            return undefined;
          }
        } else {
          if (isCurrent){
            setRole([ roleEmployee ])
            return;
          }

          setAppGlobalError({
            message: getMessageFromResponse(response.data),
            status: response.status,
          })
        }
      } catch (err) {
        setAppGlobalError({
          message: [ "" ],
          status: 500,
        })
      } finally {
        if (isAuthLoading){
          setAuthLoading(false)
        }
      }
    },
    logout: async () => {
      if (!isAuthLoading){
        setAuthLoading(true)
      }

      try {
        await actions.logout()
      } catch (e) {
        setAuthError("Произошла ошибка во время выхода")
      }

      resetStores()
      removeAllCookies()
      localStorage.clear()
    },
    patchUserRole: async (userId: string, roles: number[]) => {
      try {
        const response = await actions.patchUserRoles(userId, roles)

        if (response.status === 200 || response.status === 201) {
          return true;
        } else {
          setAppGlobalError({
            message: getMessageFromResponse(response.data),
            status: response.status,
          })

          throw new Error()
        }
      } catch {
        setAppGlobalError({
          message: [ "" ],
          status: 500,
        })

        return false;
      }
    },
    resetPassword:
        async (data: IResetPasswordData, pushPage: () => void) => {
          try {
            if (!isAuthLoading){
              setAuthLoading(true)
            }

            const response = await actions.resetPassword(data)

            if (response.status === 200 || response.status === 201) {
              pushPage()
            } else {
              setAuthError("Пользователь с введенным e-mail не найден.")
            }
          } catch (e) {
            setAuthError("Ошибка при подключении к серверу, повторите попытку позже.")
          } finally {
            if (isAuthLoading){
              setAuthLoading(false)
            }
          }
        },
    restorePassword:
        async (
          data: IRestorePasswordData,
          token: string,
          token2: string,
          onSuccess: () => void,

          // eslint-disable-next-line max-params
        ) => {
          try {
            if (!isAuthLoading){
              setAuthLoading(true)
            }

            const response = await actions.restorePassword(data, token, token2)

            if (response.status === 200 || response.status === 201) {
              onSuccess()
            } else {
              setAuthError("Произошла ошибка при сбросе пароля. Проверьте все данные или попробуйте заного отправить запрос на восстановление пароля.")
            }
          } catch (err) {
            setAuthError("Ошибка при подключении к серверу, повторите попытку позже.")
          } finally {
            if (isAuthLoading){
              setAuthLoading(false)
            }
          }
        },
    signIn: async (data: IAuthData) => {
      try {
        if (!isAuthLoading){
          setAuthLoading(true)
        }
        resetStoreUser()

        const response = await actions.signIn(data)

        if (response.status === 200 && response?.data) {
          const userId = response.data.user_id
          const accessToken = response.data.access_token
          const expiresIn = response.data.expires_in

          setTokens(userId, expiresIn, accessToken)

          // setIsAuth(true)
          // setUserId(Number(userId))

          /**
           * На текущий момент в приложении много плохого кода,
           * одной из проблем которого являются состояния запросов.
           * Чтобы их гарантировано сбросить - необходимо перезагрузить страницу после выхода.
           */
          window.location.reload()
        } else {
          setAuthError("Неверный логин или пароль.")
        }
      } catch (e) {
        setAuthError("Ошибка при подключении к серверу, повторите попытку позже.")
      }
    },
  }
}
