import { isClient } from 'config/env';
import { LOCAL_STORAGE_KEY_AUTH_REDIRECT_AFTER } from 'variables/local-storage-keys';
import { RouteNames } from 'variables/routes';

const nonRedirectPathNames: string[] = [
  RouteNames.Error,
  RouteNames.Home,
  RouteNames.PasswordReset,
  RouteNames.PasswordRestore,
  RouteNames.SignIn,
];

/**
 * Фиксируем url, по которому юзер перешел в приложение.
 * После авторизации перенаправим юзера по этой ссылке туда, куда он хотел попасть.
 */
export const saveUserUrl = () => {
  if (isClient()) {
    const { pathname } = window.location || {};

    if (pathname) {
      const isNonRedirect = nonRedirectPathNames.find(path => path === pathname);

      if (!isNonRedirect) {
        localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT_AFTER, window.location.pathname);
      }
    }
  }
};
