import React from "react"
import {
  Routes,
  Route,
} from 'react-router-dom';

import { AppLayout } from "routes/layouts/app-layout"
import {
  authRoutes,
  notFoundRoutes,
  privateRoutes,
  publicRoutes,
} from "routes/Routes"
import { AuthGuard } from "routes/wrappers/auth/AuthGuard"
import { AuthRoutesWrapper } from "routes/wrappers/auth/AuthRoutesWrapper"
import { useUserData } from "routes/wrappers/auth/hooks/useUserData"


export const RoutesWrapper = () => {

  useUserData();

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {
          publicRoutes.map(route => (
            <Route
              key={`publicRoutes-${route.id}`}
              {...route}
            />
          ))
        }

        {
          authRoutes.map(route => (
            <Route
              key={`authRoutes-${route.id}`}
              {...route}
              element={
                (
                  <AuthRoutesWrapper>
                    {route.element}
                  </AuthRoutesWrapper>
                )
              }
            />
          ))
        }

        {
          privateRoutes.map(route => (
            <Route
              key={`privateRoutes-${route.id}`}
              {...route}
              element={
                (
                  <AuthGuard>
                    {route.element}
                  </AuthGuard>
                )
              }
            />
          ))
        }

        {
          notFoundRoutes.map(route => (
            <Route
              key={`notFoundRoutes-${route.id}`}
              {...route}
            />
          ))
        }
      </Route>
    </Routes>
  );
};
