import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  FC,
  useEffect,
  useState,
} from "react"
import { Link } from "react-router-dom"

import {
  Typography,
  Input,
  Button,
  Form,
  Divider,
  Select,
} from "antd"

import {
  SortAscendingOutlined,
  UndoOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { useStaffService } from "api/hooks/useStaffService"
import { CardView } from "common/components/staff/cardView/CardView"
import { ListTable } from "common/components/staff/table/ListTable"
import { RolesWrapper } from "common/hoc/roles-wrapper/RolesWrapper"
import { useWindowSize } from "common/hooks/useWindowSize"
import isEqual from 'lodash-es/isEqual';
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { setDataInStorage } from "utils/localStorage"
import { allowedForAdmin } from "variables/roles"
import { RouteNames } from "variables/routes"


import styles from "./Staff.module.scss"

export enum EStaffCategories {
  ACTIVE,
  INACTIVE,
  ALL,
}

const initState = {
  active: EStaffCategories.ACTIVE,
  page: 1,
  per_page: 15,
  searchString: "",
  sortDirection: "asc",
  sortingField: "full_name",
}

const initialValues = {
  filter: "name_asc",
  filterActive: EStaffCategories.ACTIVE,
  search: "",
}

type TActiveFilter = {
    active: EStaffCategories,
    page: number,
    per_page: number,
    searchString: string,
    sortDirection: string
    sortingField: string
}

export const Staff: FC = () => {

  document.title = "Сотрудники"

  const { width } = useWindowSize()
  const { fetchStaff } = useStaffService()

  const [ isRequested, setRequested ] = useState<boolean>(false)

  const {
    data,
    isLoading,
  } = useTypedSelector(state => state.staff)

  const staffListStateInitial = useMemo(() => ({
    ...initState,
    page: data.pagination.current_page || initState.page,
    per_page: data.pagination.per_page || initState.per_page,
  }), [ data.pagination.current_page, data.pagination.per_page ])

  const [ staffListState, setStaffListState ] = useState<TActiveFilter>(initState)

  const {
    active,
    page,
    per_page: perPage,
    searchString,
    sortDirection,
    sortingField,
  } = staffListState

  const processStaffListStateChange = useCallback((newState: TActiveFilter) => {
    if (!isEqual(staffListState, newState)){

      fetchStaff(
        newState.searchString,
        newState.page,
        newState.per_page,
        {
          field: newState.sortingField,
          value: newState.sortDirection,
        },
        newState.active,
      )

      setStaffListState(newState)
    }
  }, [ fetchStaff, staffListState ])

  const [ form ] = Form.useForm()

  const startSearch = (value: string): void => {
    if (value) {
      processStaffListStateChange({
        ...staffListState,
        searchString: value,
      })
    }
  }

  const resetSearchData = (): void => {
    form.resetFields()
    processStaffListStateChange(initState)
  }

  const fetchDataPerPage = (p: number, per_page?: number): void => {
    setDataInStorage("STAFF_PAGE", p)
    fetchStaff(
      searchString,
      p,
      per_page,
      {
        field: sortingField,
        value: sortDirection,
      },
      active,
    )
  }

  const filterChange = (value: string) => {
    if (value === "none") {
      processStaffListStateChange({
        ...staffListState,
        sortDirection: "asc",
        sortingField: "full_name",
      })
    } else {
      const d = value.split("_")

      if (d[0] === "name") {
        processStaffListStateChange({
          ...staffListState,
          sortDirection: d[1],
          sortingField: "full_name",
        })
      } else {
        processStaffListStateChange({
          ...staffListState,
          sortDirection: d[1],
          sortingField: "position_types.description",
        })
      }
    }
  }

  const filterActiveChange = (value: EStaffCategories) => {
    processStaffListStateChange({
      ...staffListState,
      active: value,
    })
  }

  // загрузка списка сотрудников
  useEffect(() => {
    if (!isRequested){
      setRequested(true)
      fetchStaff(
        searchString,
        page,
        perPage,
        {
          field: sortingField,
          value: sortDirection,
        },
        active,
      )
    }
  }, [
    sortingField,
    sortDirection,
    searchString,
    active,
    fetchStaff,
    page,
    perPage,
    isRequested,
  ])

  return (
    <div className={styles.container}>
      <div className={styles.topMenu}>
        <Typography.Title level={3}>Сотрудники</Typography.Title>

        <RolesWrapper
          allowedRoles={allowedForAdmin}
          showEror={false}
        >
          <Link to={RouteNames.CreateUser}>
            <Button
              size="large"
              type="primary"
            >
              Добавить сотрудника
            </Button>
          </Link>
        </RolesWrapper>
      </div>

      <Divider />

      <Form
        className={styles.search}
        form={form}
        initialValues={initialValues}
        layout="inline"
      >
        <Form.Item name="search">
          <Input.Search
            allowClear
            disabled={isLoading}
            enterButton
            onSearch={startSearch}
            placeholder="Имя или должность"
          />
        </Form.Item>

        <Form.Item name="reset">
          <Button
            className={styles.reset}
            icon={<UndoOutlined />}
            onClick={resetSearchData}
            type="primary"
          />
        </Form.Item>

        <div className={styles.sortContainer}>
          <SortAscendingOutlined />

          <Form.Item name="filter">
            <Select
              className={styles.filterSelect}
              onChange={filterChange}
            >
              <Select.Option value="name_asc">ФИО | А-Я</Select.Option>

              <Select.Option value="name_desc">ФИО | Я-А</Select.Option>

              <Select.Option value="position_asc">Должность | А-Я</Select.Option>

              <Select.Option value="position_desc">Должность | Я-А</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <RolesWrapper
          allowedRoles={allowedForAdmin}
          showEror={false}
        >
          <div className={styles.sortContainer}>
            <UserOutlined />

            <Form.Item name="filterActive">
              <Select
                className={styles.filterSelect}
                onChange={filterActiveChange}
              >
                <Select.Option value={EStaffCategories.ACTIVE}>Только активные</Select.Option>

                <Select.Option value={EStaffCategories.INACTIVE}>Только неактивные</Select.Option>

                <Select.Option value={EStaffCategories.ALL}>Все пользователи</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </RolesWrapper>
      </Form>

      {
        width > 820 ? (
          <ListTable
            data={data}
            fetchData={fetchDataPerPage}
            loading={isLoading}
          />
        ) : (
          <CardView
            data={data}
            fetchData={fetchDataPerPage}
            loading={isLoading}
          />
        )
      }
    </div>
  )
}
