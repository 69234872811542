import React from 'react'
import { FC } from "react"
import {
  Link,
  useLocation,
} from "react-router-dom"

import { Breadcrumb } from "antd"

import { HomeOutlined } from "@ant-design/icons"
import styles from "routes/layouts/breadcrumbs/index.module.scss"
import { breadcrumbsNames } from "variables/routes"


export const Breadcrumbs: FC = () => {
  const location = useLocation()
  const pathSnippets = location.pathname.split("/").filter(i => i)

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`
    const nextElement = pathSnippets[index + 1]

    if (!parseInt(_)) {
      return (
        <Breadcrumb.Item key={url}>
          {
            !parseInt(nextElement) ? (
              <Link to={url}>{breadcrumbsNames[url]}</Link>
            ) : (
              <span>{breadcrumbsNames[url]}</span>
            )
          }
        </Breadcrumb.Item>
      )
    }
  
    return null
  })

  return (
    <div className={styles.breadcrumbs}>
      <Breadcrumb>
        <Breadcrumb.Item key="home">
          <Link to="/">
            <HomeOutlined /> &nbsp;Главная
          </Link>
        </Breadcrumb.Item>

        {extraBreadcrumbItems}
      </Breadcrumb>
    </div>
  )
}
