import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { checkIfRouteEquals } from "utils/routes"
import {
  RouteNames,
  routesForManager,
  routesOnlyForAdmin,
  routesForEveryone,
} from 'variables/routes';


export const useIsPage = () => {
  const { pathname } = useLocation();

  const isDocumentsCheckPage = useMemo(() => checkIfRouteEquals(RouteNames.DocumentsCheck, pathname), [ pathname ]);
  const isDocumentsRequiredPage = useMemo(() => checkIfRouteEquals(RouteNames.DocumentsRequired, pathname), [ pathname ]);
  const isErrorPage = useMemo(() => checkIfRouteEquals(RouteNames.Error, pathname), [ pathname ]);
  const isLoginPage = useMemo(() => checkIfRouteEquals(RouteNames.SignIn, pathname), [ pathname ]);
  const isPasswordResetPage = useMemo(() => checkIfRouteEquals(RouteNames.PasswordReset, pathname), [ pathname ]);
  const isPasswordRestorePage = useMemo(() => checkIfRouteEquals(RouteNames.PasswordRestore, pathname), [ pathname ]);
  const isSignInPage = useMemo(() => checkIfRouteEquals(RouteNames.SignIn, pathname), [ pathname ]);
  const isStaffPage = useMemo(() => checkIfRouteEquals(RouteNames.UserList, pathname), [ pathname ]);
  const isHomePage = useMemo(() => pathname === RouteNames.Home, [ pathname ]);
  const isVacationPage = useMemo(() => pathname === RouteNames.Vacation, [ pathname ]);

  const isForEveryonePage = useMemo(() => isHomePage || !!routesForEveryone.find(route => checkIfRouteEquals(route, pathname)), [ isHomePage, pathname ])
  const isOnlyForAdminPage = useMemo(() => !!routesOnlyForAdmin.find(route => checkIfRouteEquals(route, pathname)), [ pathname ])
  const isOpenForManagerPage = useMemo(() => !!routesForManager.find(route => checkIfRouteEquals(route, pathname)), [ pathname ])

  const isAuthRoutePage = isErrorPage
  || isLoginPage
  || isPasswordResetPage
  || isPasswordRestorePage
  || isSignInPage

  return {
    isAuthRoutePage,
    isDocumentsCheckPage,
    isDocumentsRequiredPage,
    isErrorPage,
    isForEveryonePage,
    isLoginPage: isLoginPage || isSignInPage,
    isOnlyForAdminPage,
    isOpenForManagerPage,
    isPasswordResetPage,
    isPasswordRestorePage,
    isSignInPage,
    isStaffPage,
    isVacationPage,
  };
};
