import React from 'react'
import {
  CSSProperties,
  FC,
} from "react"

import { Spin } from "antd"
import clsx from "clsx"

import { LoadingOutlined } from "@ant-design/icons"
import styles from "common/components/loader/Loader.module.scss"

type TLoader = {
  className?: string;
  style?: CSSProperties | undefined;
  withLabel?: boolean;
}

export const Loader: FC<TLoader> = props => {
  
  const {
    className,
    style,
    withLabel = false,
  } = props
  
  return (
    <div
      className={clsx(styles.containerMin, className)}
      style={style}
    >
      <Spin
        indicator={<LoadingOutlined />}
        size="large"
        tip={withLabel ? "Загрузка..." : ""}
      />
    </div>
  )
}
