import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { ILeadsData } from "models/ILeadsData"
import {
  ILeadsCurrentLeadData,
  ILeadsMetaData,
  ILeadsState,
} from "stores/redux/slices/leads/types"


const initialState: ILeadsState = {
  createResult: false,
  currentLeadData: {
    appraisal_link: "",
    appraisers: [],
    approisal_files: [] /* опечатка на бэке */,
    client: {
      contact_person: "",
      contacts: "",
      id: 0,
      name: "",
      site: "",
    },
    description: "",
    dispatch_date: 0,
    id: 0,
    manager: {
      email: "",
      id: 0,
      personal: {
        first_name: "",
        middle_name: "",
        second_name: "",
      },
    },
    name: "",
    price: 0,
    receipt_date: 0,
    refusal_reason: {
      active: false,
      id: 0,
      reason: "",
    },
    source: {
      active: false,
      id: 0,
      source: "",
    },
    status: {
      active: false,
      id: 0,
      status: "",
    },
  },
  data: {
    leads: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  deleteResult: false,
  isLoading: false,
  leadsData: {
    clients: [],
    managers: [],
    refusal_reasons: [],
    sources: [],
    statuses: [],
    users: [],
  },
}

export const leadsSlice = createSlice({
  initialState,
  name: 'leads',
  reducers: {
    resetStoreLeads(state: ILeadsState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof ILeadsState]);
    },
    setLeadsCreateResult(state: ILeadsState, action: PayloadAction<boolean>) {
      state.createResult = action.payload
    },
    setLeadsCurrentLead(state: ILeadsState, action: PayloadAction<ILeadsCurrentLeadData>) {
      state.currentLeadData = action.payload
    },
    setLeadsCurrentLeadClean(state: ILeadsState) {
      state.createResult = false
      state.currentLeadData = initialState.currentLeadData
    },
    setLeadsData(state: ILeadsState, action: PayloadAction<ILeadsData>) {
      state.data = {
        ...state.data,
        leads: action.payload.leads,
        pagination: action.payload.pagination,
      }

      state.isLoading = false
    },
    setLeadsDeleteResult(state: ILeadsState, action: PayloadAction<boolean>) {
      state.deleteResult = action.payload
    },
    setLeadsIsLoading(state: ILeadsState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setLeadsMetaData(state: ILeadsState, action: PayloadAction<ILeadsMetaData>) {
      state.leadsData = action.payload
    },
  },
});

export const {
  resetStoreLeads,
  setLeadsCreateResult,
  setLeadsCurrentLead,
  setLeadsCurrentLeadClean,
  setLeadsData,
  setLeadsDeleteResult,
  setLeadsIsLoading,
  setLeadsMetaData,
} = leadsSlice.actions;

export default leadsSlice.actions;
