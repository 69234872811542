import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { TStaffList } from 'api/hooks/useStaffService/useStaffService.types';
import { IUserData } from "models/IUserData"
import { ICurrentUserDoc } from "stores/redux/slices/current-user/types"
import {
  IStaffRole,
  IStaffState,
} from "stores/redux/slices/staff/types"


const initialState: IStaffState = {
  createResult: false,
  currentUserData: {
    active: false,
    avatar: {
      id: 0,
      url: "",
    },
    birth_date: "",
    contacts: [],
    email: "",
    first_name: "",
    id: 0,
    middle_name: "",
    note: "",
    requisites: null,
    residence_address: "",
    resume_link: null,
    second_name: "",
    specialization: undefined,
  },
  currentUserDocs: [],
  data: {
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
    users: [],
  },
  deleteResult: false,
  isLoading: false,
  roles: [],
}

export const staffSlice = createSlice({
  initialState,
  name: 'staff',
  reducers: {
    resetStoreStaff(state: IStaffState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IStaffState]);
    },
    setStaffClean(state: IStaffState) {
      const roles = state.roles
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IStaffState]);
      state.roles = roles
    },
    setStaffClearCurrentUser(state: IStaffState) {
      state.currentUserData = initialState.currentUserData
    },
    setStaffClearCurrentUserDocs(state: IStaffState) {
      state.currentUserDocs = initialState.currentUserDocs
    },
    setStaffCreateResult(state: IStaffState, action: PayloadAction<boolean>) {
      state.createResult = action.payload
    },
    setStaffCurrentUser(state: IStaffState, action: PayloadAction<IUserData>) {
      state.currentUserData = action.payload
    },
    setStaffCurrentUserDocs(state: IStaffState, action: PayloadAction<Array<ICurrentUserDoc>>) {
      state.currentUserDocs = action.payload
    },
    setStaffData(state: IStaffState, action: PayloadAction<TStaffList>) {
      state.data = {
        ...state.data,
        pagination: action.payload.pagination,
        users: action.payload.users,
      }

      state.isLoading = false
    },
    setStaffDeleteResult(state: IStaffState, action: PayloadAction<boolean>) {
      state.deleteResult = action.payload
    },
    setStaffIsLoading(state: IStaffState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setStaffRoles(state: IStaffState, action: PayloadAction<Array<IStaffRole>>) {
      state.roles = action.payload
    },
    updateCurrentUserDocument(state: IStaffState, action: PayloadAction<ICurrentUserDoc>) {
      state.currentUserDocs = state.currentUserDocs.map(el => el.id === action.payload.id ? action.payload : el)
    },
  },
});

export const {
  resetStoreStaff,
  setStaffClean,
  setStaffClearCurrentUser,
  setStaffClearCurrentUserDocs,
  setStaffCreateResult,
  setStaffCurrentUser,
  setStaffCurrentUserDocs,
  setStaffData,
  setStaffDeleteResult,
  setStaffIsLoading,
  setStaffRoles,
  updateCurrentUserDocument,
} = staffSlice.actions;

export default staffSlice.actions;
