import { isProduction } from 'config/env';

type TConsoleError = {
  message: unknown;
  optionalParams?: unknown[];
};

/**
 * Extended console error message
 * @param {TConsoleError} props
 */
export const consoleError = (props: TConsoleError) => {

  const {
    message,
    optionalParams,
  } = props;

  if (message && !isProduction()) {
    // eslint-disable-next-line no-console
    console.error(message, ...(optionalParams || []));
  }
};

/**
 * Extended console warn message
 * @param {TConsoleError} props
 */
export const consoleWarn = (props: TConsoleError) => {

  const {
    message,
    optionalParams,
  } = props;

  if (message && !isProduction()) {
    // eslint-disable-next-line no-console
    console.warn(message, ...(optionalParams || []));
  }
};

/**
 * Extended console log message
 * @param {TConsoleError} props
 */
export const consoleLog = (props: TConsoleError) => {

  const {
    message,
    optionalParams,
  } = props;

  if (message && !isProduction()) {
    // eslint-disable-next-line no-console
    console.log(message, ...(optionalParams || []));
  }
};

type TConsoleErrorGrouped = {
  groupName: string;
  messages: TConsoleError[];
  optionalParams?: unknown[];
};

/**
 * Extended group console err message
 * @param {TConsoleErrorGrouped} props
 */
export const consoleErrorGrouped = (props: TConsoleErrorGrouped) => {

  const {
    groupName,
    messages,
    optionalParams,
  } = props;

  if (messages?.length && !isProduction()) {
    /* eslint-disable no-console */
    console.group(groupName, ...(optionalParams || []));
    messages.forEach(message => console.error(message, ...(message.optionalParams || [])));
    console.groupEnd();
    /* eslint-enable no-console */
  }
};

/**
 * Extended group console warn message
 * @param {TConsoleErrorGrouped} props
 */
export const consoleWarnGrouped = (props: TConsoleErrorGrouped) => {

  const {
    groupName,
    messages,
    optionalParams,
  } = props;

  if (messages?.length && !isProduction()) {
    /* eslint-disable no-console */
    console.group(groupName, ...(optionalParams || []));
    messages.forEach(message => console.warn(message, ...(message.optionalParams || [])));
    console.groupEnd();
    /* eslint-enable no-console */
  }
};
