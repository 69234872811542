import { useMemo } from "react"

import axios, { AxiosResponse } from "axios"

import { axiosConfigPrivate } from "api/config"
import { FilesService } from "api/FilesService"
import {
  URL_PERSONAL,
  URL_USER_DATA_PERSONAL,
} from "api/urlList"
import { useProcessResponseError } from 'common/hooks/useProcessResponseError';
import { IChangeUserPasswordData } from "models/IUserData"
import { useTypedDispatch } from "stores/redux/hooks/useTypedDispatch"
import { setAppGlobalError } from 'stores/redux/slices/app';
import { setMyData } from "stores/redux/slices/current-user"

export const useCurrentUserService = () => {

  const dispatch = useTypedDispatch()
  const { processResponseError } = useProcessResponseError()

  const actions = useMemo(() => ({
    changePassword: async (data: IChangeUserPasswordData): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)

      return axios.patch(`${URL_PERSONAL}/changePass`,
        body,
        axiosConfigPrivate())
    },

    fetchUserData: async (id: number): Promise<AxiosResponse> => {
      return axios.get(`${URL_USER_DATA_PERSONAL}/${id}?with[]=personal.avatar&with[]=personal&with[]=contacts&with[]=contacts.type&with[]=specialization&with[]=specialization.positions&with[]=specialization.competencies&with[]=requisites.type`,
        axiosConfigPrivate())
    },
  }), [])

  return {
    actions,
    changePassword: async (data: IChangeUserPasswordData) => {
      try {
        const response = await actions.changePassword(data)

        if (response.status === 200 || response.status === 201) {
          return true;
        } else {
          processResponseError(response)
          return false;
        }
      } catch (e) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },
    fetchUserData: async (idUser: number) => {
      let res = true

      try {
        const response = await actions.fetchUserData(idUser)

        if (response.status === 200 || response.status === 201) {
          const {
            contacts,
            email,
            id,
            personal,
            requisites,
            specialization,
          } = response.data

          let avatar = {
            id: 0,
            url: "",
          }
          if (personal?.avatar) {
            const imageResponse = await FilesService.getFile(personal.avatar.id, "blob")
            const imageBase64 = URL.createObjectURL(imageResponse.data)
            avatar = {
              id: personal.avatar.id,
              url: `${imageBase64}`,
            }
          }

          dispatch(setMyData({
            avatar: {
              id: avatar.id,
              url: avatar.url,
            },
            birth_date: personal && personal.birth_date,
            contacts: contacts,
            email: email,
            first_name: personal && personal.first_name,
            id: id,
            middle_name: personal && personal.middle_name,
            note: personal && personal.note,
            requisites: requisites,
            residence_address: personal && personal.residence_address,
            resume_link: personal && personal.resume_link,
            second_name: personal && personal.second_name,
            specialization: specialization,
          }))

        } else {
          processResponseError(response)
          res = false
        }
      } catch (e) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))

        res = false
      }

      return res;
    },
  };
}
