import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { IEditLeadsState } from "stores/redux/slices/leads-edit/types"


const initialState: IEditLeadsState = { editSuccess: false }

export const leadsEditSlice = createSlice({
  initialState,
  name: 'leads-edit',
  reducers: {
    resetStoreLeadsEdit(state: IEditLeadsState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IEditLeadsState]);
    },
    setLeadsEditSuccess(state: IEditLeadsState, action: PayloadAction<boolean>) {
      state.editSuccess = action.payload
    },
  },
});

export const {
  resetStoreLeadsEdit,
  setLeadsEditSuccess,
} = leadsEditSlice.actions;

export default leadsEditSlice.actions;
