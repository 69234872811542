import { ENV_URL_API } from 'config/env';


// --- [main] start ---
export const URL_API = ENV_URL_API
// --- [main] end ---

// --- [authentication] start ---
export const URL_AUTHENTICATION_LOGIN = `${URL_API}/authentication/login`
export const URL_AUTHENTICATION_LOGOUT = `${URL_API}/authentication/logout`
// --- [authentication] end ---

// --- [authorization] start ---
export const URL_AUTHORIZATION = `${URL_API}/authorization`
// --- [authorization] end ---

// --- [reset password] start ---
export const URL_RESET_PASSWORD_EMAIL = `${URL_API}/reset-password/email`
// --- [registration] end ---

// --- [personal] start ---
export const URL_PERSONAL = `${URL_API}/personal`
export const URL_USER_DATA_PERSONAL = `${URL_API}/users`
// --- [personal] end ---

// --- [requisite] start ---
export const URL_REQUISITE_TYPES = `${URL_API}/requisite/types`
// --- [requisite] end ---

// --- [users] start ---
export const URL_USERS = `${URL_API}/users`
export const URL_USERS_ROLES = `${URL_API}/roles`
export const URL_MANAGERS = `${URL_API}/users/select_list`
export const URL_USERS_POSITIONS = `${URL_API}/specialization/positions/types`
export const URL_USERS_COMPETENCIES = `${URL_API}/specialization/competence/types`
export const URL_USERS_UPDATE = `${URL_API}/personal`
export const URL_USERS_UPDATE_SPEC = `${URL_API}/specialization`
export const URL_USERS_UPDATE_CONTACT = `${URL_API}/contact`
export const URL_USERS_UPDATE_REQUISITE = `${URL_API}/requisite`
// --- [users] end ---

// --- [file] start ---
export const URL_FILE = `${URL_API}/filestorage`
export const URL_USER_AVATAR = `${URL_API}/filestorage/user_avatar`
// --- [file] end ---

// --- [documents] start ---
export const URL_BASE_DOCUMENTS = `${URL_API}/documents`
export const URL_DOCUMENTS = `${URL_API}/documents/types`
export const URL_DOCUMENTS_SIGNIN = `${URL_API}/documents/for_signing`
// --- [documents] end ---

// --- [clients] start ---
export const URL_CLIENTS = `${URL_API}/clients`
// --- [clients] end ---

// --- [wiki] start
export const URL_WIKI_ARTICLES = `${URL_API}/articles`
export const URL_WIKI_SECTIONS = `${URL_API}/sections`
// --- [wiki] end ---

// --- [picker-vacation] start ---
export const URL_VACATION = `${URL_API}/vacations`
// --- [picker-vacation] end ---

// --- [widgets] start ---
export const URL_WIDGETS_WORK = `${URL_API}/users/hours_worked`
export const URL_WIDGETS_VACATIONS = `${URL_API}/vacations`
// --- [widgets] end ---


// --- [leads] start ---
export const URL_LEADS = `${URL_API}/leads`
export const URL_LEADS_STATUSES = `${URL_API}/leads/status`
export const URL_LEADS_SOURCES = `${URL_API}/leads/sources`
export const URL_LEADS_CLIENTS = `${URL_API}/clients`
export const URL_LEADS_REFUSAL_REASONS = `${URL_API}/leads/refusal_reasons`
// --- [leads] end ---

// --- [projects] start ---
export const URL_PROJECTS = `${URL_API}/projects`
export const URL_PROJECTS_PROJECTS = URL_PROJECTS /* может внутри проектов в дальнейшем будут другие категории, поэтому делаем отдельный URL */;
// --- [projects] end ---


// --- [specialization] start ---
export const URL_SPECIALIZATION = `${URL_API}/specialization`
export const URL_SPECIALIZATION_POSITIONS = `${URL_SPECIALIZATION}/positions/types`
export const URL_SPECIALIZATION_COMPETENCIES = `${URL_SPECIALIZATION}/competence/types`
export const URL_SPECIALIZATION_LEVELS = `${URL_SPECIALIZATION}/developers_levels`
// --- [specialization] end ---

// --- [work history] start ---
export const URL_WORK_HISTORY = `${URL_API}/user_histories`
// --- [work history] end ---

// --- [dismissal reasons] start ---
export const URL_DISMISSAL_REASONS = `${URL_API}/dismissal_reasons`
// --- [dismissal reasons] end ---
