import { useDispatch } from "react-redux"

import { bindActionCreators } from "redux"

import { reduxAllActions } from "stores/redux"

export const useActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(reduxAllActions, dispatch)
}
